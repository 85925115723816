/**=====================
    Breadcrumb scss
==========================**/

.breadcrumb{
    @include flex_common ($dis: flex, $align: center, $justify: center);
    margin: 0;
    margin-top: 10px;
    .breadcrumb-item{
        // font-size: 16px;
        font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
        color: $white;
        +.breadcrumb-item{
            &:before{
                color: $white;
            }
        }
    }
}

.breadcrumb-icon {
	--bs-breadcrumb-divider: '>';

	li {
		display: inline-block;
	}
}