/**=====================
     Accordion scss
==========================**/

.accordion {
     .accordion-item {
          border: none;
          background-color: transparent;

          .accordion-button {
               background-color: transparent;
               width: max-content;
               padding: 0;
               border: none;
               box-shadow: none;

               &:not(.collapsed) {
                    &:after {
                         background-image: url(../../assets/images/svg/arrow.svg);
                    }
               }
          }

          .accordion-body {
               padding: 0;
          }
     }
}

.filter {
     .accordion {
          margin-bottom: 10px;

          .accordion-item {
               .accordion-header {
                    border-bottom: 1px solid $gray-color;
                    padding-bottom: 8px;

                    .accordion-button {
                         width: 100%;
                         @include flex_common($dis: flex, $align: center, $justify: space-between);
                         font-weight: 500;
                         color: $title-color;

                         &:after {
                              background-size: 16px;
                         }
                    }
               }

               .accordion-body {
                    padding: 16px 0;

                    .filter-body {
                         .service {
                              border-radius: 8px;
                              padding: 16px;
                              background-color: $white;
                              display: flex;
                              flex-direction: column;
                              gap: 10px;
                         }

                         .form-group {
                              margin: 0;
                         }

                         .search-provider {
                              margin-top: 10px;
                         }
                    }

                    .accordion {
                         .accordion-item {
                              .search-div {
                                   @include flex_common($dis: flex, $align: center, $justify: space-between);
                                   background-color: $white;
                                   border-radius: 8px;
                                   padding: 10px 16px;

                                   i {
                                        --Iconsax-Size: 18px;
                                        --Iconsax-Color: #808b97;
                                   }

                                   .form-group {
                                        width: 100%;

                                        input {
                                             border: none;
                                             width: 100%;
                                        }
                                   }

                                   .accordion-button {
                                        &:after {
                                             background-size: 16px;
                                        }
                                   }
                              }

                              .accordion-body {
                                   padding: 16px;

                                   .search-body {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 12px;
                                        height: 170px;
                                        overflow: auto;

                                        .form-check {
                                             gap: 8px;
                                             margin-left: 24px;
                                             margin-bottom: 0;
                                        }

                                        ul {
                                             width: 100%;
                                             overflow: hidden;
                                             text-overflow: ellipsis;
                                             display: block;
                                             white-space: nowrap;

                                             li {
                                                  position: relative;
                                                  color: $light-color;
                                                  font-size: 14px;
                                                  line-height: 18px;
                                                  padding: 0 10px;

                                                  &:before {
                                                       position: absolute;
                                                       content: "";
                                                       top: 4px;
                                                       right: 0px;
                                                       background-color: $light-color;
                                                       width: 1px;
                                                       height: 12px;
                                                  }

                                                  &:first-child {
                                                       color: $title-color;
                                                       padding-left: 0;
                                                  }

                                                  &:last-child {
                                                       padding-right: 0;

                                                       &:before {
                                                            display: none;
                                                       }
                                                  }
                                             }
                                        }
                                   }
                              }
                         }
                    }
               }
          }
     }

     .card-footer {
          padding-top: 0 !important;

          button {
               width: 100%;
               justify-content: center;
               border-radius: 8px;
          }

          &:before,
          &:after {
               display: none;
          }
     }
}

.faq-section,
.terms-section,
.privacy-section {
     .accordion {
          .accordion-item {
               background-color: $white;
               border-radius: 12px;

               +.accordion-item {
                    margin-top: 20px;
               }

               .accordion-header {
                    .accordion-button {
                         padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                         display: flex;
                         align-items: center;
                         justify-content: space-between;
                         width: 100%;
                         line-height: 1.2;
                         font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                         font-weight: 500;
                         gap: 4px;
                         color: $title-color;

                         i {
                              --Iconsax-Size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
                              --Iconsax-Color: #00162E;
                         }

                         &:after {
                              display: none;
                         }

                         .add {
                              display: none;
                         }

                         .minus {
                              display: flex;
                         }

                         &.collapsed {

                              color: rgba($title-color, 0.7);

                              .add {
                                   display: flex;
                              }

                              .minus {
                                   display: none;
                              }
                         }
                    }
               }

               .accordion-body {
                    padding: 20px;

                    p {
                         font-size: 18px;
                         line-height: 1.5;
                    }
               }
          }
     }

     ul,
     ol {
          list-style-type: circle;
          padding-left: 1.25rem;


          li {
               display: list-item;
               font-size: 17px;
               color: rgba($title-color, 0.7);
               line-height: 1.3 !important;
               margin-bottom: 12px;
          }
     }
}

.up-down-image {
     display: flex;
     gap: 20px;

     img {
          border-radius: 12px;
          background-color: $gray-color;
     }
}