/**=====================
    Footer scss
==========================**/

.footer-section{
    background-color: $title-color;
    padding: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320))) 0;

    .copyright{
        @include flex_common ($dis: flex, $align: center, $justify: space-between);
        gap: 10px;
        border-bottom: 1px solid rgba($white, 0.15%);
        padding-bottom: 10px;
        margin-bottom: 30px;
        .footer-logo{
            img{
                height: 32px;
            }
        }
        p{
            color: $white;
            margin: 0;
            font-size: 18px;
        }
        @include mq-max(sm){
            flex-direction: column;
            align-items: start;
        }
    }

    i {
        --Iconsax-Size: 22px;
        --Iconsax-Color: #575756;
    }

    .heading-footer{
        font-weight: 400;
        line-height: 26px;
        color: $white;
        margin-bottom: 14px;
        @include flex_common_1 ($dis: flex, $align: center);
        gap: 8px;
        font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        @include mq-max(sm){
            margin-bottom: 6px;
        }
    } 

    ul{
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        gap: 14px;

        @include mq-max(sm){
            gap: 6px;
        }
        li{
            color: rgba($white, 0.7%);
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            .nav-link{
                color: rgba($white, 0.7%);
                padding: 0;
            }
        }
    }

    .btn{
        border-radius: 13px;
        width: max-content;
        padding: 10px calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
        margin-top: 40px;
        margin-left: 30px;
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    @include mq-max(sm){
        &.nav-folderized{
            .nav{
                &.open{
                    ul{
                        display: flex;
                    }
                }
                ul{
                    display: none;
                }
            }
        }
    }

}
