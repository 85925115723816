/**=====================
    Policy scss
==========================**/

.list-items {
    padding-left: 1.25rem;
    list-style-type: circle;

    .list-item {
        margin-bottom: 20px;
        display: list-item;

        h6 {
            font-size: 18px;
            margin-bottom: .75rem;
            font-weight: 500;
            line-height: 1.5;
            color: $title-color;
        }

        p {
            font-size: 17px !important;
            color: rgba($title-color, 0.7);
            line-height: 1.3 !important;
            margin-bottom: 16px;
        }
    }

    li {
        margin-bottom: 12px;
    }
}