/**=====================
     Loader scss
==========================**/

#loader {
    position: absolute;
    background: white;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
  }
  
  .notLoaded {
    height: 100vh;
    overflow: hidden;
    position: fixed;
    margin: 0px;
    width: 100%;
  }
  
  .page-loader .page-loader-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 12px;
  }
  
  .img{
      background-image: url('../images/logo.png');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
      height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
      animation: 2s anim-lineXY ease-out infinite;
  }