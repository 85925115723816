/**=====================
     About Us scss
==========================**/

.about-us-section,
.about-us-section-1 {
    position: relative;

    .about-us-content {
        margin-right: 0.3px;

        [dir="rtl"] & {
            margin-left: 0.3px;
            margin-right: unset;
        }

        .card {
            .card-body {
                padding: 35px 30px;

                .card-title {
                    @include flex_common_1 ($dis: flex, $align: center);
                    gap: 10px;

                    img {
                        width: 70px;
                        height: 70px;
                        border-radius: 100%;

                        &.star {
                            width: 20px;
                            height: 20px;
                        }

                        &.quote,
                        &.quote-active {
                            position: absolute;
                            top: calc(20px + (30 - 20) * ((100vw - 1200px) / (1920 - 1200)));
                            right: calc(20px + (30 - 20) * ((100vw - 1200px) / (1920 - 1200)));
                            width: calc(30px + (50 - 30) * ((100vw - 1200px) / (1920 - 1200)));
                            height: calc(30px + (50 - 30) * ((100vw - 1200px) / (1920 - 1200)));
                            border-radius: 0;

                            @include mq-max(xl) {
                                width: calc(30px + (50 - 30) * ((100vw - 575px) / (1920 - 575)));
                                height: calc(30px + (50 - 30) * ((100vw - 575px) / (1920 - 575)));
                            }
                        }

                        &.quote-active {
                            display: none;
                        }

                        &.quote {
                            display: block;
                        }
                    }
                }

                .card-detail {
                    padding: 24px 0 0;
                    margin-top: 20px;
                    border-top: 1px dashed rgba($title-color, 0.1%);

                    p {
                        margin: 0;
                        color: $light-color;
                        font-size: 16px;
                        line-height: 24px;
                        width: 100%;
                        overflow: hidden;
                        display: -webkit-box;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        .swiper-wrapper {
            height: 310px;

            .swiper-slide {
                transition: all 200ms linear;
                transform: translateY(50px);

                &.swiper-slide-active {
                    transform: translateY(0px);

                    .card {
                        border-color: rgba($primary-color, 0.30%);
                        background-color: rgba($primary-color, 0.10%);

                        .card-title {
                            .quote-active {
                                display: block;
                            }

                            .quote {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .image-1 {
            top: 140px;
            left: 20px;
            height: 16px;
            position: absolute;
            animation: mover 2s infinite alternate;
            animation-delay: 1s;

            @include mq-max(lg) {
                display: none;
            }
        }

        .swiper-pagination {
            left: 0;
            width: 70px;
            right: 0;
            margin: 0 auto;
            bottom: 0;

            .swiper-pagination-bullet {
                height: 8px;
                width: 8px;
                background-color: $primary-color;
            }
        }

        .swiper-button-next3 {
            bottom: 0;
            top: unset;
            right: 43%;
            z-index: 1;
            left: unset;
        }

        .swiper-button-prev3 {
            bottom: 0;
            right: unset;
            top: unset;
            left: 43%;
            z-index: 1;
        }
    }
}

.more-section,
.feature-section {
    .more-content {
        h3 {
            font-size: calc(20px + (22 - 20) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
            margin-bottom: 4px;
        }

        p {
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            color: $light-color;
            font-weight: 500;

            a {
                color: $primary-color;
            }
        }
    }


    .feature-content {
        .feature-box {
            width: 45px;
            height: 45px;
            border-radius: 4px;
            background-color: $primary-color;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            margin-bottom: 12px;

            i {
                --Iconsax-Size: 24px;
                --Iconsax-Color: #fff;
            }

        }

        p {
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            margin: auto;
            margin-top: 4px;
            width: 90%;
            font-weight: 500;
            color: rgba($title-color, 0.6);
        }

        .feature-list {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
            margin-top: 16px;

            .list-item {
                padding: 8px calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
                border-radius: 6px;
                border: 1px solid rgba($title-color, 0.1);
                font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 500;
                line-height: 1.2;
                display: flex;
                align-items: center;
                gap: 8px;
                color: rgba($title-color, 0.8);

                i {
                    --Iconsax-Size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                    --Iconsax-Color: #575756;
                }
            }
        }

    }

}

.about-us {
    .image-grp {
        display: flex;
        align-items: start;
        gap: 20px;

        img {
            height: auto;
            width: 50%;
            border-radius: 12px;

            &:nth-child(even) {
                margin-top: 80px;
            }
        }
    }

    .content-detail {
        color: rgba($title-color, 0.8);

        p {
            font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1.2;
            margin-bottom: 20px;
        }

        .item-lists {
            list-style: none;
            margin: 0;
            gap: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));

            li {
                display: flex;
                align-items: start;
                gap: 16px;

                &::before {
                    display: none;
                }


                .icon-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    height: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
                    width: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
                    min-width: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
                    background-color: $primary-color;
                }

                i {
                    --Iconsax-Size: 18px;
                    --Iconsax-Color: #fff;
                }

                .detail {
                    h4 {
                        font-weight: 600;
                        line-height: 1.2;
                        margin-bottom: 4px;
                    }

                    p {
                        line-height: 1.2;
                        font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.about-us-section-1 {
    .title-1 {
        width: 100%;
    }

    .about-us-content {
        .card {
            box-shadow: 0 0 8px rgba($title-color, 0.15);
            border-radius: 4px;

            .card-body {
                padding: 20px;

                .card-detail {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    text-align: center;
                    margin: 0;
                    border: none;
                    padding: 0;
                    align-items: center;

                    i {
                        --Iconsax-Size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
                        --Iconsax-Color: #575756;
                    }

                    .avatar {
                        width: 50px;
                        height: 50px;
                        border-radius: 100%;
                    }

                    .hr {
                        background-color: $gray-color;
                        height: 2px;
                        width: 25px;
                    }

                    h3 {
                        font-size: 16px;
                        color: $light-color;
                    }

                    .rate {
                        justify-content: center;
                        margin-top: 8px;
                        gap: 8px;
                    }

                    p {
                        color: rgba($title-color, 0.8);
                        overflow: unset;
                        display: block;
                        font-size: 17px;
                    }
                }

            }
        }

        .swiper {
            padding: 0 4px;

            .swiper-wrapper {
                height: auto;
                padding: 5px 0;

                .swiper-slide {
                    transform: unset;
                    transition: none;

                    &.swiper-slide-active {
                        transform: none;

                        .card {
                            border: none;
                            background-color: unset;
                        }
                    }
                }
            }
        }

    }
}

.our-work {
    .our-work-content {
        .work-box {
            padding: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320))) calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 6px;
            background-color: $section-bg;
            text-align: center;

            h3 {
                font-size: calc(20px + (28 - 20) * ((100vw - 320px) /(1920 - 320)));
                font-weight: 600;
                line-height: 1.2;
                color: $primary-color;
            }

            p {
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) /(1920 - 320)));
                text-align: center;
                display: inline-block;
                font-weight: 600;
                color: rgba($title-color, 0.6);
            }
        }
    }
}