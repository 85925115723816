/**=====================
    Home Section scss
==========================**/

.home-section {
    position: relative; 
    background-image: url(../../assets/images/bg.jpg);  
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
    z-index: 0; 
    padding-top: calc(60px + (70 - 60) * ((100vw - 320px) / (1920 - 320)));

    .home-icon {
        img {
            position: absolute;
            animation: mover 2s infinite alternate;
            z-index: -1;

            &.image-1 {
                top: 180px;
                right: -65px;
                animation-delay: 1s;
                width: 140px;
                overflow: hidden;
                @include mq-max(sm){
                    display: none;
                 }
            }

            &.image-2 {
                top: calc(200px + (280 - 200) * ((100vw - 992px) / (1920 - 992)));
                left: 60px;
                animation-delay: 2s;
                height: 120px;
                @include mq-max(lg){
                   display: none;
                }
            }

            &.image-3 {
                animation-delay: 3s;
                height: 140px;
                animation-delay: 3s;
                height: 140px;
                filter: brightness(0.5);
                transform: rotate3d(3, 2, 1, 180deg);
                animation: unset;
                top: calc(220px + (320 - 220) * ((100vw - 992px) / (1920 - 992)));
                right: calc(20px + (240 - 20) * ((100vw - 992px) / (1920 - 992)));
                @include mq-max(lg){
                    display: none;
                }
            }
        }
    }

    .home-slider {
        margin-top: calc(220px + (490 - 220) * ((100vw - 320px) / (1920 - 320)));

        @include mq-max(lg){
            margin-top: calc(320px + (340 - 320) * ((100vw - 320px) / (1920 - 320)));
        }

        @include mq-max(md){
            margin-top: calc(200px + (300 - 200) * ((100vw - 320px) / (1920 - 320)));
        }

        .service-slider {
            .swiper-wrapper {
                 height: 372px;
                 transition-timing-function : linear;
                @include mq-max(lg){
                    height: 332px;
                }
                @include mq-max(sm){
                    height: 300px;
                }
                .swiper-slide {
                    position: relative;
                    padding-top: 10px;

                    .service-card {
                        height: 100%;
                        .img-box {
                            height: 100%;
                            .bg-size {
                                border-top-right-radius: 25px;
                                border-top-left-radius: 25px;
                                width: 100%;
                                height: 100%;
                                background-position: top center !important;
                            }
                        }

                        .service-content {
                            background: linear-gradient(180deg, rgba(0, 10, 28, 0) 0%, rgba(0, 10, 28, 0.4990371148459384) 56%, rgba(0, 10, 28, 0.9332107843137255) 100%);
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            text-align: center;
                            color: rgba($white, 0.60%);
                            padding: 90px 0 16px;
                            font-size: 18px;

                            span {
                                text-align: center;
                                position: relative;

                                &:before {
                                    content: "";
                                    position: absolute;
                                    width: 5px;
                                    height: 5px;
                                    background-color: rgba($white, 0.60%);
                                    border-radius: 100%;
                                    top: 50%;
                                    left: -12px;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                    }

                    &:nth-child(even) {
                        padding-top: calc(40px + (100 - 40) * ((100vw - 320px) / (1920 - 320)));
                    }
                }
            }

        }
    }

    .home-contain {
        position: absolute;
        top: calc(80px + (160 - 80) * ((100vw - 320px) / (1920 - 320)));
        text-align: center;
        left: 0px;
        right: 0;
        width: 90%;
        text-align: center;
        margin: 0 auto;

        @include mq-min(lg) {
            left: -40px;
        }
        @include mq-max(lg){
            top: 90px;
        }

        h1 {
            color: $white;
            line-height: 1;
            @include mq-max(lg) {
                width: 70%;
                margin-inline: auto;
            }
            @include mq-max(md) {
                width: 90%;
            }
            @include mq-max(sm) {
                width: 100%;
            }
        }

        p {
            color: $light-color;
            font-size: 20px;
            padding-top: 50px;
            width: 45%;
            line-height: 23px;
            margin: 0 auto;
            
            @include mq-max(4xl){
                width: 55%;
            }
            @include mq-max(2xl){
                font-size: 18px;
            }
            @include mq-max(xl){
                width: 65%;
                padding-top: 40px;
            }
            @include mq-max(lg){
                display: none;
            }
        }

        .home-form-group {
            padding-top: 40px;
            // width: 40%;
            width: 700px;
            margin: 0 auto;

            @include mq-min(lg){
                padding-top: calc(15px + (30 - 15) * ((100vw - 992px) / (1920 - 992)));
            }

            @include mq-max(md){
               display: none;
            }

            .input-group {
                padding: 10px;
                border-radius: 40px !important;
                border: 1px solid rgba($white, 0.20%);
                border-radius: 0;
                @include flex_common_1 ($dis: flex, $align: center);
                gap: 12px;
                flex-wrap: nowrap;

                .position-relative {
                    i {
                        --Iconsax-Size: 18px;
                        --Iconsax-Color: #808B97;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 14px;
                    }

                    .form-control {
                        padding: 10px 16px 10px 37px;
                        color: $white;
                        
                    }
                    ::placeholder{
                        color: $light-color;
                    }
                }
                .btn {
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                }
            }

        }

        .home-animation {
            position: relative;
            line-height: 1;
            margin-left: 35px;
            text-transform: uppercase;
            font-size: calc(22px + (60 - 22) * ((100vw - 320px) / (1920 - 320)));
            letter-spacing: 2px;
            font-weight: 900;
            background: linear-gradient(to right,
                    rgba(251, 64, 64, 1) 20%, rgba(255, 84, 156, 1) 30%, rgba(114, 94, 254, 1) 70%, rgba(36, 119, 255, 1) 80%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            text-fill-color: transparent;
            background-size: 150% auto;
            animation: textShine 1s ease-in-out infinite alternate;

            .shape {
                border-radius: 35px;
                position: absolute;
                top: -21px;
                left: -27px;
                z-index: -1;
                width: calc(375px + (560 - 375) * ((100vw - 992px) / (1920 - 992)));
                height: calc(81px + (108 - 81) * ((100vw - 992px) / (1920 - 992)));
                @include mq-max(lg){
                    // width: calc(260px + (560 - 260) * ((100vw - 320px) / (1920 - 320)));
                    // height: calc(46px + (81 - 46) * ((100vw - 320px) / (1920 - 320)));

                    // // width: 260px;
                    // // height: 46px;
                    // // width: 330px;
                    // // height: 58px;

                    // top: -10px;
                    // left: -15px;

                    width: calc(250px + (530 - 250) * ((100vw - 320px) / (1920 - 320)));
                    height: calc(46px + (90 - 46) * ((100vw - 320px) / (1920 - 320)));
                    top: -12px;
                    left: -23px;
                
                }
            }

            @include mq-max(lg) {
               margin-top: 25px;
               margin-left: 0;
            }
        }
    }

    &.ratio_18 { 
        .home-contain {
            width: 100%;
            top: calc(80px + (140 - 80) * ((100vw - 320px) / (1920 - 320)));

            h2 {
                font-size: calc(20px + (42 - 20) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 700;
                background: linear-gradient(to right,
                        rgba(251, 64, 64, 1) 20%, rgba(255, 84, 156, 1) 30%, rgba(114, 94, 254, 1) 50%, rgba(36, 119, 255, 1) 80%);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                text-fill-color: transparent;
                background-size: 150% auto;
                width: max-content;
                margin: 0 auto;
                position: relative;
                &:before, &:after{
                    position: absolute;
                    content: "";
                    background-color: $primary-color;
                    // height: 34px;
                    height: calc(14px + (34 - 14) * ((100vw - 320px) / (1920 - 320)));
                    width: 4px;
                    border-radius: 6px;
                    top: 4px;
                    @include mq-max(2xl){
                        top: 7px;
                    }
                }
                &:before{
                    // left: -35px;     
                    left: calc(-25px + ((-40) - (-25)) * ((100vw - 320px) / (1920 - 320)));            
                }
                &:after{
                    // right: -35px; 
                    right: calc(-25px + ((-40) - (-25)) * ((100vw - 320px) / (1920 - 320)));            

                }
                span{
                    &:before, &:after{
                        position: absolute;
                        content: "";
                        background-color: $primary-color;
                        // height: 58px;
                        height: calc(28px + (58 - 28) * ((100vw - 320px) / (1920 - 320)));
                        width: 4px;
                        border-radius: 6px;
                        top: -8px;
                        @include mq-max(2xl){
                            top: -3px;
                        }
                    }
                    &:before{
                        // left: -25px;  
                        left: calc(-15px + ((-30) - (-15)) * ((100vw - 320px) / (1920 - 320)));            

                    }
                    &:after{
                        // right: -25px;  
                        right: calc(-15px + ((-30) - (-15)) * ((100vw - 320px) / (1920 - 320)))
                    }
                }
            }

            p {
                font-size: 16px;
                line-height: 24px;
                max-width: 680px;
                width: 90%;
                padding-top: calc(20px + (30 - 20) * ((100vw - 991px) / (1920 - 991)));
                @include mq-max(lg) {
                    display: none;
                }
            }
            h3{
                color: $white;
                margin-top: 30px;
                font-size: 30px;

                @include mq-max(lg) {
                    display: none;
                }
            }
        }
        .bg-size{
            &:before{
                @include mq-max(xl){
                    padding-bottom: calc((170px) + ((-60) - (170)) * ((100vw - 320px) / (1920 - 320)));
                }
                
                @include mq-max(lg){
                    padding-bottom: calc((60px) + ((-70) - (60)) * ((100vw - 320px) / (1920 - 320)));
                }

                @include mq-min(xl){
                    padding-bottom: calc((110px) + ((-70) - (110)) * ((100vw - 991px) / (1920 - 991)))
                }
            }
        }
    }
}