/**=====================
    Contact scss
==========================**/

.contact-section {
    .heading-p {
        font-size: calc(16px + (17 - 16) * ((100vw - 320px) / (1920 - 320)));
        line-height: 1.3;
        margin: 20px 0 calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
        color: rgba($title-color, 0.7);
    }

    .contact-content {
        .contact-us-form {
            padding: 20px;
            border-radius: 12px;
            border: 1px solid $gray-color;
            background-color: $white;
        }

        .contact-info {
            display: flex;
            flex-direction: column;
            gap: 16px;

            li {
                border-radius: 6px;
                font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 500;
                line-height: 1.2;
                display: flex;
                align-items: start;
                gap: 8px;
                color: $light-color;
                width: auto;

                i {
                    --Iconsax-Size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                    --Iconsax-Color: #00162E;
                }

                .detail {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    padding: 0;

                    h5 {
                        color: $title-color;
                        font-weight: 500;
                    }

                    p {
                        color: rgba($title-color, 0.7);
                        line-height: 1.3;
                        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
                    }
                }
            }
        }
    }
}

.map-section {
    .map {
        width: 100%;
        height: calc(200px + (400 - 200) * ((100vw - 320px) / (1920 - 320)));

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}