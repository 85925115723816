/**=====================
     Category scss
==========================**/

.category-img {
     border-radius: 10px;
}

.category-span {
     text-align: center;
     display: block;
     margin-top: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
     font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
     font-weight: 500;
}

.accordion{
     .accordion-item{
          border: none;
          .accordion-button{
               background-color: transparent;
               width: max-content;
               padding: 0;
               border: none;
               box-shadow: none;
               &:not(.collapsed){
                    &:after{
                         background-image: url(../../assets/images/svg/arrow.svg);
                    }
               }
          }
     }
}