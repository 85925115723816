/**=====================
    Auth scss
==========================**/

.log-in-section {
    width: 100%;
    margin: 0 auto;
    overflow: auto;
    position: relative;
    height: 100vh;

    .login-content {
        height: 100%;
    }
}

.image-contain {
    background-color: $primary-color;
    padding: 50px;
    text-align: center;
    flex-direction: column;
    @include flex_common ($dis: flex, $align: center, $justify: center);
    height: 100%;

    .logo {
        height: 50px;
        margin: 0 auto;
        display: flex;
        margin-bottom: 30px;
    }

    .auth-image {
        width: 40%;
    }

    .auth-content {
        color: $white;
        margin-top: 50px;

        h2 {
            text-transform: uppercase;
            line-height: 41px;
            font-weight: 700;
        }

        p {
            font-size: 16px;
            line-height: 20px;
        }

        .app-install {
            margin-top: 30px;
            @include flex_common ($dis: flex, $align: center, $justify: center);
            gap: 16px;

            img {
                height: 50px;
            }
        }
    }
}

.login-main {
    background-color: $section-bg;
    height: 100%;
    position: relative;
    @include flex_common ($dis: flex, $align: center, $justify: center);

    .language-dropdown {
        position: absolute;
        top: 40px;
        right: 40px;
        @include flex_common ($dis: flex, $align: center, $justify: end);
        gap: 6px;
        padding: 5px 8px;
        border-radius: 30px;
        border: 1px solid rgba($title-color, 0.10%);
        background-color: $white;
        margin-bottom: 30px;
        width: max-content;
        margin-left: auto;

        img {
            width: 24px;
            height: 24px;
            border-radius: 100%;
        }

        i {
            --Iconsax-Size: 18px;
            --Iconsax-Color: #00162E;
        }

        .language-btn {
            background: transparent;
            color: $title-color;
            border: none;
            line-height: 1;
            padding: 0;
            @include flex_common_1 ($dis: flex, $align: center);
            gap: 12px;

            span {
                @include flex_common_1 ($dis: inline-flex, $align: center);
                gap: 6px;
                line-height: 1;
                font-size: 16px;
            }
        }

        .onhover-show-div {
            min-width: 120px;
            transition: all 0.3s linear;
            position: absolute;
            z-index: 3;
            border: 1px solid rgba($title-color, 0.10%);
            background-color: $white;
            opacity: 0;
            visibility: hidden;
            transform: translate3d(0, -5%, 0);
            top: 37px;
            right: 0;
            padding: 15px;
            border-radius: 4px;


            [dir="rtl"] & {
                left: 0;
                right: unset;
            }

            .lang {
                cursor: pointer;
                transition: all 0.3s;
                @include flex_common_1 ($dis: flex, $align: center);
                border: none;
                color: $title-color;
                background-color: transparent;
                gap: 8px;
                font-size: 13px;
                padding: 0;

                img {
                    border-radius: 0 !important;
                    width: auto !important;
                    height: auto !important;
                }

                &:hover {
                    color: var(--theme-color);
                    transform: translateX(5px);

                    [dir="rtl"] & {
                        transform: translateX(-5px);
                    }
                }

                +li {
                    margin-top: 8px;
                }
            }
        }

        &:hover {
            .onhover-show-div {
                opacity: 1;
                visibility: visible;
                transform: none;
            }
        }
    }

    .login-card {
        width: 100%;
        @include flex_common ($dis: flex, $align: center, $justify: center);
        flex-direction: column;
        background-color: white;
        width: $login-card;
        border-radius: $login-br;
        margin: 20px auto;

        .login-title {
            width: 100%;
            border-bottom: 1px dashed $gray-color;
            padding: 25px;
            text-align: center;

            h2 {
                font-weight: 700;
                line-height: 33px;
            }

            p {
                color: $light-color;
                padding-top: 6px;
                font-size: 16px;
                margin: 0;
            }
        }

        .login-detail {
            background-color: $section-bg;
            border-radius: 10px;
            width: calc(100% - 80px);
            margin: 30px 0;
            padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));

            .form-group {
                position: relative;

                i {
                    --Iconsax-Size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                    --Iconsax-Color: #808B97;
                    position: absolute;
                    top: calc(42px + (45 - 42) * ((100vw - 320px) / (1920 - 320)));
                    left: 15px;

                }

                .toggle-password {
                    position: absolute;
                    top: calc(42px + (45 - 42) * ((100vw - 320px) / (1920 - 320)));
                    right: 15px;
                    left: unset;
                    cursor: pointer;

                    i {
                        position: static;
                    }

                    .eye-slash {
                        display: none;
                    }

                    &.eye {
                        i.eye {
                            display: block;
                        }

                        i.eye-slash {
                            display: none;
                        }
                    }

                    &.eye-slash {
                        i.eye-slash {
                            display: block;
                        }

                        i.eye {
                            display: none;
                        }
                    }
                }

                label {
                    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                    font-weight: 500;
                    line-height: 23px;
                    margin-bottom: 8px;
                    position: relative;

                    &:before {
                        position: absolute;
                        content: "";
                        background-color: $primary-color;
                        height: 30px;
                        width: 4px;
                        top: -3px;
                        left: -30px;
                        border-radius: 4px;
                    }
                }

                .form-control {
                    border-radius: 8px;
                    padding: calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320))) calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320))) calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320))) 45px;
                    background-color: $white;
                    color: rgba($title-color, 0.8);
                    border: none;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }

            .forgot-pass {
                margin-top: 12px;
                text-align: end;

                a {
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            .btn {
                margin-top: 40px;
                border-radius: 8px;
                width: 100%;
                justify-content: center;
                font-size: 18px;

                &:focus {
                    box-shadow: none;
                }
            }

            .not-member {
                text-align: center;
                font-size: 18px;
                font-weight: 400;
                color: $light-color;
                line-height: 20px;
                padding-top: 10px;

                small {
                    color: $primary-color;
                    font-weight: 500;
                }
            }

        }

        .other-options {
            width: 100%;
            padding: 30px 40px 40px;
        }

        .options {
            position: relative;
            text-align: center;
            width: max-content;
            margin: 0 auto;
            color: $light-color;
            font-size: 18px;
            display: flex;

            &:before,
            &:after {
                position: absolute;
                content: "";
                background-color: $light-color;
                width: 22px;
                height: 1px;
                top: 50%;
                transform: translateY(-50%);
            }

            &:before {
                left: -30px;
            }

            &:after {
                right: -30px;
            }
        }

        .social-media {
            @include flex_common ($dis: flex, $align: center, $justify: center);
            gap: 20px;
            margin-top: 24px;

            .social-icon {
                background-color: $section-bg;
                border-radius: 100%;
                border: 1px solid rgba($title-color, 0.1);
                height: 70px;
                width: 70px;
                @include flex_common ($dis: flex, $align: center, $justify: center);
            }
        }

        @include mq-max(sm) {
            width: 100%;
        }
    }

}

.terms {
    p {
        margin: 0;
        color: $light-color;
    }
}

.login-img {
    width: 250px;
    padding-top: 30px;
}

.otp-field {
    @include flex_common_1 ($dis: flex, $align: center);
    gap: 12px;

    input {
        width: 50px;
        height: 50px;
        border: none;
        background-color: $white;
        border-radius: 8px;
        text-align: center;
    }
}