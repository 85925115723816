/**=====================
     About Us scss
==========================**/

.service-package-section {
    .service-package-content {
        .service-detail {
            color: $white;
            margin: 30px;
            width: auto;

            .service-icon {
                border-radius: 10px;
                background-color: $gray-color;
                padding: 12px;
                width: 60px;
                height: 60px;
                margin-bottom: 20px;
            }

            .price {
                @include flex_common ($dis: flex, $align: center, $justify: space-between);
                gap: 4px;
                margin-top: 6px;

                span {
                    font-size: 26px;
                    font-weight: 700;
                    line-height: 33px;
                }

                i {
                    --Iconsax-Size: 24px;
                    --Iconsax-Color: white;
                    transform: translateX(0px);
                    transition: all 0.2s ease;
                }
                &:hover{
                    i{
                        transform: translateX(5px);
                        transition: all 0.2s ease;
                    }
                }
            }
        }

        .service-bg {
            width: 100%;
            border-radius: 15px;
            height: 200px;
            position: relative;
            overflow: hidden;

            .service-1 {
                position: absolute;
                bottom: -90px;
                right: -160px;
                height: 250px;
            }

            .service-2 {
                position: absolute;
                top: -120px;
                right: -90px;
                height: 250px;
                transform: rotate(230deg);
            }

            .service-3 {
                position: absolute;
                bottom: -100px;
                left: -70px;
                height: 250px;
                transform: rotate(49deg);
            }

            .service-4 {
                position: absolute;
                top: -92px;
                left: -80px;
                height: 250px;
                transform: rotate(190deg);

            }
            &:hover{
                .service-icon{
                    img{
                        animation: tada 1.5s ease infinite;
                    }
                }
            }
        }

        .service-bg-primary {
            background-color: rgba($primary-color, 0.80%);
        }

        .service-bg-secondary {
            background-color: $secondary-color;
        }

        .service-bg-info {
            background-color: $info-color;
        }

        .service-bg-success {
            background-color: $success-color;
        }
    }
}
.service-title {
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    gap: 8px;

    h4 {
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
        text-overflow: ellipsis;
    }

    span {
        font-size: 14px;
        line-height: 20px;
        color: $light-color;
    }

    small {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
    }
    @include mq-max(sm){
        flex-direction: column;
        align-items: start;
    }
}

.service-detail {
    ul {
        @include flex_common_1 ($dis: flex, $align: start);

        li {
            border-right: 1px solid $gray-color;
            padding: 0 10px;

            [dir="rtl"] & {
                border-left: 1px solid $gray-color;
                border-right: unset;

                &:first-child {
                    padding-left: unset;
                    padding-right: 0;
                }
    
                &:last-child {
                    padding-right: unset;
                    padding-left: 0;
                }
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                border: none;
                padding-right: 0;
                width: 100%;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }
        }
        // @include mq-max(sm){
        //     flex-direction: column;
        //     align-items: start;
        //     li{
        //         padding: 0;
        //         border: none;
        //     }
        // }
    }

    

    p {
        font-size: 14px;
        color: $light-color;
        position: relative;
        padding: 0 0 0 22px;
        margin: 0;
        margin-top: 12px;
        line-height: 23px;

        &:before {
            content: "";
            position: absolute;
            width: 5px;
            height: 5px;
            background-color: $light-color;
            border-radius: 100%;
            top: 10px;
            left: 6px;
        }
    }
}

.time {
    @include flex_common_1 ($dis: flex, $align: center);
    gap: 4px;
    color: $success-color;
    font-size: 14px;

    i {
        --Iconsax-Size: 18px;
        --Iconsax-Color: #27AF4D;
    }

    span {
        font-weight: 500;
    }
}

.date-time{
    li{
        @include flex_common ($dis: flex, $align: center, $justify: space-between);
        gap: 4px;
        flex-wrap: wrap;
        span{
            color: $light-color;
            font-size: 16px;

        }
        small{
            font-weight: 500;
            color: $title-color;
            font-size: 16px;
        }
    }
}

.service-list-section {
      .service-list-content {
        .card {
            .card-footer {
                position: relative;

                &:before {
                    content: "";
                    width: 91%;
                    position: absolute;
                    height: 1px;
                    border: 1px dashed $gray-color;
                    left: 15px;
                    top: 0;
                    transform: translateY(-50%);
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: 14px;
                    top: -3px;
                    border-bottom: 2px solid $gray-color;
                    border-right: 2px solid $gray-color;
                    width: 6px;
                    height: 6px;
                    transform: rotate(312deg);
                }

                .footer-detail {
                    @include flex_common_1 ($dis: flex, $align: center);
                    gap: 8px;

                    img {
                        width: 45px;
                        height: 45px;
                        border-radius: 100%;

                        &.star {
                            width: 16px;
                            height: 16px;
                        }
                    }

                    p {
                        margin: 0;
                        font-weight: 500;
                        color: $title-color;
                        font-size: 14px;
                        width: 100%;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        word-break: break-all;
                        text-overflow: ellipsis;
                    }

                    i {
                        --Iconsax-Size: 18px;
                        --Iconsax-Color: #FFC412;
                    }
                }

                a {
                    @include flex_common_1 ($dis: flex, $align: center);
                    gap: 2px;

                    &.btn {
                        width: max-content;
                        &.btn-outline {
                            color: $primary-color;
                            font-size: 18px;
                            line-height: 1;
                            @include mq-max(3xl){
                                padding: 10px;
                            }
                            @include mq-max(md){
                                font-size: 14px;
                                padding: 10px 6px;
                                i{
                                    --Iconsax-Size: 17px;
                                }
                            }
                        }
                    }

                    i {
                        --Iconsax-Size: 19px;
                        --Iconsax-Color: #0019ff;
                    }
                }
            }
        }

        .service-detail-slider{
            .service-img{
                border-radius: 15px;
                
            }
        }

        .service-detail{
            p{
                width: 100%;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }
        }

        .select-dropdown{
            @include mq-max(md){
                flex-direction: column;
                align-items: start;
                gap: 12px;
            }
            
            .form-group{
                @include mq-max(md){
                    width: 100%;
                }
            }
        }
        .col-custom-3{
            @include mq-max(xl){
                position: relative;
            }
            // .filter{
            //     @include mq-max(xl){
            //         position: absolute;
            //         top: 55px;
            //         // left: -500px;
            //         left: 12px;
            //         transition: all 0.3s ease;
            //         z-index: 3;
            //         width: 290px;
            //         transform: translateY(-10px);
            //         opacity: 0;
            //         visibility: hidden;
            //         &.open{
            //             left: 12px;
            //             transition: all 0.3s ease;
            //             transform: translateY(0px);
            //             opacity: 1;
            //         visibility: visible;
            //         }
            //     }
            // }
        }
    }
}

.detail-content{
    margin-top: 24px;
    .title{
        @include flex_common ($dis: flex, $align: center, $justify: space-between);
        gap: 10px;
        border: none;
        margin: 0;
        padding-bottom: 4px;

        &:before{
            display: none;
        }
        h3{
            font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 500;
        }
        @include mq-max(sm){
                flex-direction: column;
                align-items: start;
        }
    }
    p{
        color: $light-color;
        font-size: 16px;
        margin: 12px 0;
    }
    label{
        font-size: 16px;
        margin-bottom: 4px;
    }
    .lists{
        @include flex_common ($dis: flex, $align: center);
        justify-content: start;
        flex-wrap: wrap;
        gap: 12px;
        margin-bottom: 24px;
    }
    .detail-sec{
        background-color: $section-bg;
        border-radius: 10px;
        .overview-list{
            color: $light-color;
        }
    }
}

.service-item{
    gap: 16px;
    @include flex_common_1 ($dis: flex, $align: center);
    border-bottom: 1px solid $gray-color;
    &:first-child{
        padding-top: 0;
    }
    &:last-child{
        border: none;
        padding-bottom: 0;
    }
    img{
        width: 120px;
        height: 120px;
    }
    @include mq-max(sm){
        flex-direction: column;
        align-items: start;
    }
}

.amount-value{
    font-weight: 700;
    font-size: 22px;
    line-height: 40px;
}

.amount{
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid $gray-color;
}
.amount-header{
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    background-color: rgba($primary-color, 0.10%);
    color: $primary-color;
    padding: 16px;
    span{
        font-size: 16px;
    }
    small{
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
    }
}
.amount-detail{
    padding: 16px;
    
    i{
        --Iconsax-Size: 24px;
        --Iconsax-Color: #00162E;
        border-right: 1px solid $gray-color;
        padding-right: 10px;
    }
    ul{
        display: flex;
        flex-direction: column;
        gap: 10px;
        li{
            @include flex_common_1 ($dis: flex, $align: center);
            gap: 12px;
            font-size: 16px;
            font-weight: 500;
            color: $title-color;
        }
    }
}

.service-img{
    .favourite{
        top: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));;
        right: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));;
        left: unset;
    }
}

.favourite{
    width: max-content;
    border-radius: 100%;
    background-color: $danger-color;
    padding: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;

    .nolike{
        --Iconsax-Size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        --Iconsax-Color: white;
        display: block;
        &.hide{
            display: none;
        }
    }
    .like{
        height: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320))) !important;
        width: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320))) !important;
        display: none;
        &.show{
            display: block;
        }
    }
}


.servicemen-lists{
    background-color: rgba($primary-color, 0.10);
    width: 100%;
    padding: 16px;
    border: 1px dashed $primary-color;
    color: $primary-color;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
}