/**=====================
     Form Wizard scss
==========================**/

.basic-wizard {
	.stepper-horizontal {
        padding: 20px;
		width: 100%;
		margin: 0 auto;
		margin-bottom: 24px;
        border-bottom: 1px solid $gray-color;
		display: flex;
        justify-content: center;
        gap: 20px;

        @include mq-max(md){
            flex-direction: column;
            gap: 16px;
        }

		.step {
			position: relative;
            @include flex_common_1 ($dis: flex, $align: center);
            gap: 12px;
            padding-inline: 30px;
            &:last-child{
                &:after{
                    display: none;
                }
            }

            &:after{
                content: "";
                border-bottom: 1px dashed $primary-color;
                height: 1px;
                width: 50px;
                position: absolute;
                top: 50%;
                right: -35px;
                @include mq-max(md){
                    display: none;
                }
            }

            .step-title {
				font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));;
				white-space: nowrap;
				color: $light-color;
			}        

            &.stepper{
                .step-circle{
				    border: 1px dashed $primary-color;
                    color: $primary-color;
                }
                .step-title{
                    color: $primary-color;
                    font-weight: 600;
                }
            }

			&:first-child {
				padding-left: 0;
                @include mq-max(md){
                    padding-right: 0;
                }
			}

			&:last-child {
				padding-right: 0;
                @include mq-max(md){
                    padding-left: 0;
                }
			}

			&:last-child .step-bar-left,
			&:last-child .step-bar-right {
				display: none;
			}

			.step-circle {
				width:calc(40px + (45 - 40) * ((100vw - 320px) / (1920 - 320)));
                height:calc(40px + (45 - 40) * ((100vw - 320px) / (1920 - 320)));
                border-radius: 8px;
                background-color: white;
                @include flex_common ($dis: flex, $align: center, $justify: center);
				font-size: 18px;
				font-weight: 500;
			}

			&.done {
				.step-circle {
					background-color: $primary-color;
                    border: 1px solid $primary-color;
					color: $white;
					position: relative;

					&:before {			
                        content: "";
                        position: absolute;                       
                        top: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
                        left: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
                        background-image: url(../../assets/images/svg/tick.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        z-index: 0;
                        width: 20px;
                        height: 20px;
                    }

					* {
						display: none;
					}
				}

				.step-title {
					color: $primary-color;
				}
                ~.step{
                    .step-circle{
                        border: 1px dashed $primary-color;
                        color: $primary-color;
                    }
                    .step-title{
                        color: $primary-color;
                        font-weight: 600;
                    }
                }
			}
			.step-title,
			.step-optional {
				text-align: center;
			}
		}

	}
}

.service-booking{
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    width: 100%;
    ul{
        .booking-list{
            padding-bottom: 35px;
            position: relative;
            &:before{
                position: absolute;
            content: "";
            border: 1px dashed $light-color;
            opacity: 0.3;
            top: 12px;
            left: 3px;
            height: 100%;
            }
            &:last-child{
                padding: 0;
                &:before{
                    display: none;
                }
            }
        }
    }
    h3{
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 500;
        line-height: 23px;
        color: $title-color;
        margin-bottom: 12px;
    }
    .delivery-location{
        background-color: $white !important;
        border: 1px solid $gray-color !important;
        border-radius: 10px;
        margin-bottom: 0;

        .location-header{
            padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            @include flex_common ($dis: flex, $align: center, $justify: space-between);
            gap: 4px;
            border-bottom: 1px solid $gray-color;
            
            @include mq-max(sm){
                flex-direction: column;
                align-items: start;
            }

            .loaction-icon{
                border-radius: 100%;
                height: 50px;
                min-width: 50px;
                width: 50px;
                background-color: $gray-color;
                @include flex_common ($dis: flex, $align: center, $justify: center);
                @include mq-max(sm){
                    height: 35px;
                    min-width: 35px;
                    width: 35px;
                }
                img{
                    height: 24px;
                    @include mq-max(sm){
                        height: 16px;
                    }
                }
            }
            .active-icon{
                border: 1px solid $primary-color;
                border-radius: 100%;
                height: 50px;
                min-width: 50px;
                width: 50px;
                @include flex_common ($dis: flex, $align: center, $justify: center);
                img{
                    height: 24px;
                    background-color: $primary-color;
                    border: 1px solid $white;
                    padding: 10px;
                    border-radius: 100%;
                    height: 42px;
                    min-width: 42px;
                    width: 42px;
                    @include flex_common ($dis: flex, $align: center, $justify: center);
                }
            }
            .name{
               h4{
                font-weight: 500;
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
               } 
               span{
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
               }
            }
            .badge{
                @include mq-max(sm){
                    margin-left: 50px;
                }
            }
        }
        .address{
            padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            p{
                font-size: 16px;
            }
            .btn-outline{
                border-width: 1px;
                width: max-content;
                padding:  9px 18px;
            }
        }
        .action{
            @include flex_common_1 ($dis: flex, $align: center);
            gap: 16px;
            padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            padding-top: 0;
            width: max-content;
            position: relative;
            .btn{
                width: max-content;
                padding: calc(3px + (5 - 3) * ((100vw - 320px) / (1920 - 320))) 12px;
                border-radius: 8px;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            }
            .radio{
                position: absolute;
                top: 1px;
                left: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
                width: calc(104px + (115 - 104) * ((100vw - 320px) / (1920 - 320)));
                height: calc(33px + (37 - 33) * ((100vw - 320px) / (1920 - 320)));
                opacity: 0;
                cursor: pointer;
            }
        }
        .radio:checked {
            ~button{
                background-color: $primary-color;
                color: $white;
            }
        }
    }
    input[type="radio"]{
        &:checked{
            ~.delivery-location{
               .btn-outline{
                background-color: $primary-color;
                color: $white;
               } 
            }
        }
    }
    .add-location{
        color: $primary-color;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        &:hover{
            text-decoration: underline;
        }
    }
    .booking-data{
        margin-top: -8px;
        width: 100%;
        margin-left: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));

        .select-option{
            flex-direction: row;
            background-color: transparent;
            border: none;
            background-color: $white;
            padding: 0 0 20px 0 ;
            gap: calc(4px + (40 - 4) * ((100vw - 320px) / (1920 - 320)));
            flex-wrap: wrap;
            .form-check{
                border: none;
                padding: 0;
                flex-direction: row;
                min-height: 1.5rem;
            }
        }
        .text-success{
            font-size: 18px;
        }
        .servicemen-list-item{
            width: max-content;
            min-width: 276px;
            background-color: $section-bg !important;
            border: none !important;
            box-shadow: none !important;
            @include mq-max(xs){
                min-width: 188px;
                width: auto;
            }
        }
    }
    .form-control{
        background-color: $white;
        border-radius: 10px;
        padding: 16px;
        color: $title-color;
        &:hover, &:focus{
            color: $title-color;
            background-color: $white;
        }
    }
}

.activity-dot{
    min-width: 8px;
    width: 8px;
    height: 8px;
    background-color: $light-color;
    border-radius: 100%;
    outline: 1px solid $light-color;
    position: relative;
    z-index: 0;

    &:after{
        content: "";
        position: absolute;
        height: 16px;
        width: 16px;
        border: 1px solid $light-color;
        border-radius: 100%;
        top: -4px;
        left: -4px;
    }
}

.wizard-footer{
    @include flex_common_1 ($dis: flex, $align: center);
    gap: 10px;
    border-top: 1px dashed $gray-color;
    padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320))) calc(47px + (60 - 47) * ((100vw - 320px) / (1920 - 320)));
    .btn-outline{
        border-width: 1px;
    }
    button{
        width: max-content;
        padding: calc(4px + (10 - 4) * ((100vw - 320px) / (1920 - 320))) calc(12px + (24 - 12) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
}

.date-time-picket-sec{
    background-color: $white;
    border-radius: 10px;
    border: 1px solid $gray-color;
    padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
}
.date-time-picker{
    @include flex_common_1 ($dis: flex, $align: center);
    gap: 12px;
    @include mq-max(md){
        flex-wrap: wrap;
    }
    input{
        color: $title-color !important;
    }
}

.input-icon{
    position: absolute;
    top: calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320)));
    left: 16px;
    --Iconsax-Size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    --Iconsax-Color: #808B97;
    z-index: 5;
}

.edit-option{
    background-color: unset;
    border: none;
    padding: 0;
    color: $primary-color;
    text-decoration: underline;
    font-size: 18px;
}

.selected-men{
    background-color: $white;
    padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 10px;
    @include flex_common_1 ($dis: flex, $align: center);
    flex-wrap: wrap;
    gap: 12px;
}
