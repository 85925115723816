/**=====================
    Tabs scss
==========================**/

.custom-nav-tabs{
    margin-top: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
    border: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    overflow: auto hidden;
    gap: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    .nav-item{
        border: none;
        .nav-link{
            border-radius: 10px;
            padding:  calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
            display: flex;
            flex-direction: column;
            background-color: $white;
            border: 1px solid $gray-color;
            margin-bottom: 0;

            &.active, &:hover{
                background-color: rgba($primary-color,0.10);
                border: 1px solid rgba($primary-color,0.01);
                span{
                    color: $primary-color;
                    font-weight: 500;
                }
                img{
                    background-color: $white;
                    &.inactive{
                        display: none;
                    }
                    &.active{
                        display: block;
                    }
                }
            }
            .img-box{
                width: 100%;
                height: calc(70px + (95 - 70) * ((100vw - 320px) / (1920 - 320)));
                min-width: calc(70px + (95 - 70) * ((100vw - 320px) / (1920 - 320)));
                margin-bottom: 12px;
            }
            img{
                // margin: 0 auto;
                background-color: rgba($gray-color,0.4);
                padding: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
                border-radius: 4px;                
                width: 100%;
                height: 100%;
                &.active{
                    display: none;
                }
                &.inactive{
                    display: block;
                }
            }
            span{
                margin: 0 auto;
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                color: $title-color;
                line-height: 26px;
                white-space: nowrap;
            }
            small{
                margin: 0 auto;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: rgba($title-color,0.6);
                line-height: 20px;
                white-space: nowrap;
            }
        }
    }
}

.tab-content{
    margin-top: 30px;
}

.review-tab{
    margin: 0;
    .tab-pane{
        padding: 20px;
        min-height: 290px;
    } 
    .nav-item{
        .nav-link{
            padding: 10px 20px;
            font-size: 16px;
            font-weight: 500;
            color: $light-color;
            border: none;
            &.active{
                background-color: transparent;
                border: none;
                color: $title-color;
                border-bottom: 2px solid $title-color;
                &:hover{
                 border-bottom: 2px solid $title-color;

                }
            }
            &:hover{
                border: none;
            }
        }
    }
}

.favorite-tab{
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    gap: 4px;
    border-bottom: 1px solid $gray-color;
    padding-bottom: 16px;
    h3{
        font-weight: 700;
    }
    .nav-tabs{
        border: none;
        gap: 12px;
        .nav-item{
            .nav-link{
                background-color: rgba($primary-color,0.10);
                color: $primary-color;
                border: none;
                border-radius: 10px;
                font-size: 18px;
                padding: 9px 27px;
                &.active{
                    background-color: $primary-color;
                    color: $white;
                    font-weight: 500;
                }
            }
        }
    }
}

.provider-detail-tab{
    .nav-tabs{
        flex-direction: column;
        gap: 16px;
        .nav-item{
            .nav-link{
                border: 2px solid $primary-color;
                color: $primary-color;
                border-radius: 8px;
                width: 100%;
                font-size: 18px;
                font-weight: 500;
                padding: 12px;
                background-color: transparent;
            }
        }
    }
}

.overview-list{
    list-style: disc;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    li{
        display: list-item;
        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
    }
}

.provider-service-tab{
    padding-bottom: 16px;
    gap: 12px;
    .nav-item{
        .nav-link{
            background-color: rgba($primary-color,0.10);
            color: $primary-color;
            border: none;
            border-radius: 30px;
            font-size: 16px;
            padding: 6px 16px;
            &.active{
                background-color: $primary-color;
                color: $white;
                font-weight: 500;
            }
        }
    }
}
