/**=====================
     Reset scss
==========================**/

// Section Space Scss

section,
.section-t-space {
    padding-top: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
    overflow: hidden;
}

.section-b-space {
    padding-bottom: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
}

.section-big-space {
    padding-top: calc(30px + (150 - 30) * ((100vw - 320px) / (1920 - 320)));
}

.content-t-space {
    padding-top: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.content-t-space2 {
    padding-top: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.content-b-space {
    padding-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
}


.content-b-space2 {
    padding-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.container-fluid-xl {
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (100 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.container-fluid-lg {
    padding: 0 calc(15px + (200 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.container-fluid-md {
    padding: 0 calc(15px + (245 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.container-fluid-sm {
    padding: 0 calc(15px + (385 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.container-fluid-xs {
    padding: 0 calc(15px + (450 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.section-bg {
    background-color: $section-bg;
}

.p-sticky {
    // position: sticky;
    // top: 120px;
}

// Number Spin Button Scss
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


// Rating Scss
.rate {
    @include flex_common_1 ($dis: flex, $align: center);
    gap: 4px;
    margin-top: 4px;

    small {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
    }

    img {
        &.star {
            width: 13px;
            height: 13px;
        }
    }
}


// Title Scss
.title {
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    padding-bottom: calc(7px + (14 - 7) * ((100vw - 320px) / (1920 - 320)));
    border-bottom: 1px solid $gray-color;
    position: relative;
    margin-bottom: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
    gap: 4px;

    &.dark-title {
        color: $white;
        border-bottom: 1px solid rgba($white, 0.10%);

    }

    &:before {
        content: "";
        position: absolute;
        background-color: var(--theme-color);
        height: 4px;
        width: 93px;
        bottom: -2.5px;
        left: 0;
    }

    h2 {
        font-weight: 700;
        line-height: calc(30px + (41 - 30) * ((100vw - 320px) / (1920 - 320)));

        @include mq-max(sm) {
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }
    }

    .view-all {
        @include flex_common_1 ($dis: flex, $align: center);
        gap: 4px;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 500;
        position: relative;
        white-space: nowrap;

        i {
            --Iconsax-Size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            --Iconsax-Color: #575756;
            transform: translateX(0px);
            transition: all 0.2s ease;
        }

        &:hover {
            text-decoration: underline;

            i {
                transform: translateX(5px);
                transition: all 0.2s ease;
            }
        }
    }
}

.title-1 {
    @include flex_common ($dis: flex, $align: center, $justify: center);
    position: relative;
    margin: 0 auto;
    width: calc(250px + (340 - 250) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;

    h2 {
        font-weight: 700;
        line-height: calc(30px + (41 - 30) * ((100vw - 320px) / (1920 - 320)));
    }

    &:before {
        content: "";
        position: absolute;
        background-color: var(--theme-color);
        height: 4px;
        width: 93px;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
    }
}


// Discount Tag Scss
.discount-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: $danger-color;
    color: $white;
    border-radius: 22px;
    padding: 1px 10px;
    font-size: 14px;
    font-weight: 600;
}


// Newsletter Scss
.newsletter-section {
    .newsletter-content {
        position: relative;
        background-image: url(../../assets/images/newsletter.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        padding-top: 1px;

        @include mq-max(md) {
            background-image: none;
        }

        .newsletter-detail {
            color: $white;
            padding: 100px 0 30px;
            padding: calc(60px + (100 - 60) * ((100vw - 992px) / (1920 - 992))) 0 calc(20px + (30 - 20) * ((100vw - 992px) / (1920 - 992)));
            padding-right: calc(40px + (100 - 40) * ((100vw - 992px) / (1920 - 992)));

            @include mq-max(md) {
                padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
                // padding-right: calc(40px + (100 - 40) * ((100vw - 992px) / (1920 - 992)));
                background-image: url(../../assets/images/newsletter-1.png);
                background-position: center;
                background-size: cover;
                background-size: unset;
                border-radius: 20px;
                background-repeat: no-repeat;
            }

            h2 {
                text-transform: uppercase;
                font-weight: 700;
                line-height: calc(30px + (41 - 30) * ((100vw - 320px) / (1920 - 320)));
            }

            p {
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                line-height: 23px;
                color: rgba($white, 0.60%);
                margin: 0;
                width: 100%;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }

            .form-group {
                @include flex_common_1 ($dis: flex, $align: center);
                gap: 6px;
                padding-top: 24px;
                margin: 0;

                .form-control {
                    border-radius: 6px;
                    background-color: $white;
                    color: $light-color;

                    @include mq-max(sm) {
                        padding: 6px 10px;
                    }
                }

                .btn {
                    border-radius: 6px;
                    width: max-content;

                    span {
                        padding: 0;
                    }

                    @include mq-max(sm) {
                        padding: 5px 10px;
                        font-size: 14px;

                        span {
                            display: none;
                        }
                    }
                }
            }

            span {
                padding-top: calc(10px + (60 - 10) * ((100vw - 768px) / (1920 - 768)));
                font-size: 18px;

                @include mq-max(md) {
                    padding-top: 10px;
                }
            }
        }

        .newsletter-icons {
            @include mq-max(md) {
                display: none;
            }

            img {
                position: absolute;
                animation: mover 2s infinite alternate;
                animation-delay: 1s;
            }

            .newsletter-1 {
                top: 140px;
                left: 70px;
                height: 90px;
            }

            .newsletter-2 {
                top: 50px;
                right: 90px;
                height: 16px;

                @include mq-max(lg) {
                    display: none;
                }
            }

            .newsletter-3 {
                bottom: -30px;
                right: 330px;
                height: 90px;

                @include mq-max(2xl) {
                    display: none;
                }
            }
        }
    }
}


// Custom Scroll Scss
.custom-scroll {

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: $gray-color;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba($primary-color, 0.20%);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $primary-color;
    }
}


// Position Sticky
.position-sticky {
    // position: sticky;
    // top: 110px;
    // margin-bottom: 30px; 
}


// Text Scss
.text-underline {
    text-decoration: underline;
}

.text-dark {
    color: $title-color;
    font-weight: 500;
}

.text-white {
    color: $white;
}


// Border Scss
.border {
    border: 1px solid $gray-color;
}

.b-top {
    border-top: 1px solid $gray-color;
}

.b-bottom {
    border-bottom: 1px solid $gray-color;
}

.b-left {
    border-left: 1px solid $gray-color;
}

.b-right {
    border-right: 1px solid $gray-color;
}

.border-dashed {
    border: 1px dashed $gray-color;
}

.border-solid {
    border: 1px solid $gray-color;
    margin: 16px 0;
}


.b-top-dashed {
    border-top: 1px dashed $gray-color;
}

.b-bottom-dashed {
    border-bottom: 1px dashed $gray-color;
}

.b-left-dashed {
    border-left: 1px dashed $gray-color;
}

.b-right-dashed {
    border-right: 1px dashed $gray-color;
}

.br-12 {
    border-radius: 12px;
}

.br-15 {
    border-radius: 15px;
}

.br-10 {
    border-radius: 10px;
}

.br-8 {
    border-radius: 8px;
}

.br-6 {
    border-radius: 6px;
}


.br-tl-0 {
    border-top-left-radius: 0;
}

.br-tr-0 {
    border-top-right-radius: 0;
}

.br-bl-0 {
    border-bottom-left-radius: 0;
}

.br-br-0 {
    border-bottom-right-radius: 0;
}


// Padding Scss
.p-16 {
    padding: 16px;
}

.p-20 {
    padding: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.pr-45 {
    padding-right: 45px !important;
}

// Image Scss
.img-45 {
    width: 45px !important;
    height: 45px !important;
    border-radius: 100%;
}

.plus-minus,
.increment {
    background-color: $section-bg;
    border-radius: 6px;
    @include flex_common_1 ($dis: flex, $align: center);
    gap: 0;

    i {
        --Iconsax-Size: 19px;
        --Iconsax-Color: #808B97;
        cursor: pointer;
        @include flex_common ($dis: flex, $align: center, $justify: center);
        width: 29px;
        height: 29px;
    }

    input {
        color: $title-color;
        border: none;
        background-color: $section-bg;
        text-align: center;
        width: 30px;
        height: 30px;
    }

    .add {
        background-color: $primary-color;
        --Iconsax-Color: #fff;
        border-radius: 6px;
    }
}

.danger-note {
    background-color: rgba($danger-color, 0.10%);
    color: $danger-color;
    padding: 16px;
    border-radius: 8px;

    p {
        font-size: 16px;
    }

    h3 {
        color: $danger-color !important;
        margin-bottom: 4px;
    }
}

.dashed-border {
    border-top: 1px dashed $gray-color;
    margin: calc(14px + (20 - 14) * ((100vw - 768px) / (1920 - 768))) 0;
}

.note {
    margin-top: 16px;

    label {
        font-weight: 500;
        color: $title-color;
    }

    p {
        color: $light-color;
    }
}

.input-group {
    &:not(.has-validation) {
        >:not(:last-child) {
            &:not(.dropdown-toggle) {
                &:not(.dropdown-menu) {
                    border-radius: 8px !important;
                }
            }
        }

        >.dropdown-toggle {
            &:nth-last-child(n+3) {
                border-radius: 8px !important;
            }
        }
    }

    // >:not(:first-child){
    //     &:not(.dropdown-menu){
    //         &:not(.valid-tooltip){
    //             &:not(.valid-feedback){
    //                 &:not(.invalid-tooltip){
    //                     &:not(.invalid-feedback){
    //                         border-radius: 8px !important;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}

@include mq-min(4xl) {
    .row-cols-3xl-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333%;
    }
}

.filter-div {
    display: flex;
    align-items: center;
    gap: 12px;

    a {
        border-radius: 6px;
        padding: 6px 12px;
    }

    @include mq-max(md) {
        width: 100%;
    }
}

.filter {
    @include mq-max(xl) {
        position: absolute;
        top: 55px;
        // left: -500px;
        left: 12px;
        transition: all 0.3s ease;
        z-index: 3;
        width: 290px;
        transform: translateY(-10px);
        opacity: 0;
        visibility: hidden;

        &.open {
            left: 12px;
            transition: all 0.3s ease;
            transform: translateY(0px);
            opacity: 1;
            visibility: visible;
        }
    }
}

.time-slot {
    border: none;
    background: none;
    padding: 0;
    font-size: 16px;
    color: #808B97 !important;
}

.error-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
}

.no-data-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 20px;

    img {
        margin-bottom: 20px;
    }

    p {
        color: rgba($title-color, 0.8);
        font-size: calc(17px + (18 - 17) * ((100vw - 320px) / (1920 - 320)));
    }

    .btn {
        margin-top: 25px;
        width: max-content;

        i {
            --Iconsax-Size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
            --Iconsax-Color: #fff;
            transform: translateX(0px);
            transition: all 0.2s ease;
        }

        &:hover {
            i {
                transform: translateX(-5px);
                transition: all 0.2s ease;
            }
        }
    }
}