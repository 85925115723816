/**=====================
    Card scss
==========================**/

.card{
    background-color: $white;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid $gray-color;
    // margin-bottom: 30px;
    // margin-bottom: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin: 0;
    .card-footer{
        padding: 16px;
        background-color: transparent;
        border: none;
        gap: 8px;
        @include flex_common ($dis: flex, $align: center, $justify: space-between);
    }
    .card-img{
        img{
            width: 100%;
            height: 100%;
        }
    }
    &.dark-card{
        background-color: rgba($white, 0.10%);
        color: $light-color;
        border: none;
    }
    &.gray-card{
        background-color: $section-bg;
        border: none;
    }
}