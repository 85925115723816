/**=====================
    Booking scss
==========================**/

.booking-category{
    .form-control{
        border: none !important;
    }
    .category-body{
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .form-check{
            justify-content: space-between;
            gap: 4px;
            ul{
                @include flex_common_1 ($dis: flex, $align: center);
                gap: 10px;
                li{
                    padding-left: 10px;
                    position: relative;
                    font-size: 16px;
                    img{
                        width: 24px;
                        height: 24px;
                    }
                    &:before{
                        content: "";
                        position: absolute;
                        top: 6px;
                        left: 0;
                        background-color: $gray-color;
                        height: 16px;
                        width: 1px;
                    }
                    &:first-child{
                        &:before{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .booking-category-body{
        height: unset !important;
        overflow: unset !important;
    }
}

.booking-sec{
    .filter-div{
        @include mq-max(md){
            width: auto;
        }
        @include mq-max(sm){
            width: 100%;
        }
    }
    .select-dropdown{
        h4{
            font-weight: 500;
        }
        @include mq-max(sm){
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;

            .form-group {
                width: 100%;
            }
        }
    }
    .service-title{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        // h4{
        //     margin-bottom: 4px;
        // }

        span{
            font-size: 18px;
            font-weight: 700;
            line-height: 23px;
            color: $title-color;
        }
        small{
            font-size: 15px;
            font-weight: 500;
            line-height: 19px;
        }
        h4{
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        }
    }
    .status, .view-status{
        @include flex_common ($dis: flex, $align: center, $justify: space-between);
        margin-bottom: 16px;
        .status-btn, h5{
            font-size: 22px;
            font-weight: 500;
            line-height: 28px;
            color: $primary-color;
            border: none;
            background-color: unset;
            padding: 0;
            &:hover{
                text-decoration: underline;
            }
        }
        .badge{
            padding: 9px 16px;
        }
    }
    .data{
        display: flex;
        flex-direction: column;
        gap: 8px;
        li{
            @include flex_common ($dis: flex, $align: center, $justify: space-between);
            font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
        }
        .label{
            color: $light-color;
            @include flex_common_1 ($dis: flex, $align: center);
            gap: 8px;
        }
        .value{
            font-weight: 500;
            &.location{
                font-weight: 400 !important;
            }
        }
        i{
            --Iconsax-Size: 18px;
            --Iconsax-Color: #575756;
        }
    }
    .selected-men{
        padding: 0;
        .servicemen-list-item{
            background-color: $section-bg;
            border: none;
            box-shadow: none;
            min-width: 279px;
            @include mq-max(2xs){
                min-width: 262px;
            }
            .list{
                width: 100%;
                >div{
                    width: 100%;
                    ul{
                        justify-content: space-between;
                        li{
                            .rate{
                                &:before{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ratio_70{
        .bg-size{
            min-height: 100px;
            min-width: 100px;
            width: auto;
        }
    }
}

.date-time-loaction-btn{
    background-color: unset;
    padding: 0;
    border: none;
}