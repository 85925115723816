/**=====================
     Cart scss
==========================**/

.cart{
    background-color: $section-bg;
    .cart-header{
        border-bottom: 1px solid $gray-color;
        padding: 20px;
        span{
            color: $light-color;
            margin-top: 4px;
            font-size: 16px;
        }
    }
    .cart-body{
        padding: 20px;
        h5{
            font-weight: 500;
        }
        .cart-img{
            img{
                height: 250px;
            }
        }
        .cart-item{
            background-color: $white;
            border: 1px solid $gray-color;
            border-radius: 8px;
            margin-bottom: 20px;

            &:last-child{
                margin: 0;
            }
            .cart-heading{
                padding: 16px;
                border-bottom: 1px solid $gray-color;
                gap: 4px;
                @include flex_common ($dis: flex, $align: center, $justify: space-between);
                @include mq-max(xs){
                    flex-direction: column;
                    align-items: flex-start;
                }
                
                .cart-title{
                    @include flex_common_1 ($dis: flex, $align: center);
                    gap: 6px;
                    p{
                        margin: 0;
                    }
                    .rate{
                        margin: 0;
                        .star{
                            width: 13px;
                            height: 13px;
                        }
                    }
                    .img-45{
                        @include mq-max(xs){
                            width: 35px !important;
                            height: 35px !important;
                        }
                    }
                }
                .cart-action{
                    @include flex_common_1 ($dis: flex, $align: center);
                    gap: 12px;
                    @include mq-max(xs){
                        gap: 8px;
                        margin-left: 40px;
                    }
                    button{
                        background-color: unset;
                        border: none;
                        padding: 0;
                        @include mq-max(xs){
                            .edit, .delete{
                                width: 30px;
                                height: 30px;
                                --Iconsax-Size: 17px;
                            }
                        }
                    }
                }
            }
            .cart-detail{
                padding: 16px;
                .selected-service{
                    @include flex_common_1 ($dis: flex, $align: start);
                    gap: 16px;
                    @include mq-max(sm){
                        flex-direction: column;
                        align-items: start;
                    }
                    .selected-img{
                        width: 160px;
                        height: 160px;
                        @include mq-max(lg){
                            width: 120px;
                            height: 120px;
                        }
                        @include mq-max(md){
                            width: 100px;
                            height: 100px;
                        }
                    }

                    .mw-80{
                        min-width: 80px;
                    }

                    .service-info{
                        width: 100%;
                        p{
                            margin: 0;
                        }
                        .date {
                            @include flex_common_1 ($dis: flex, $align: center);
                    
                            li {
                                border-right: 1px solid $gray-color;
                                padding: 0 10px;
                                font-size: 13px;
                    
                                &:first-child {
                                    padding-left: 0;
                                }
                    
                                &:last-child {
                                    border: none;
                                    padding-right: 0;
                                }
                            }
                        }
                        .servicemen-list-item{
                            width: max-content;
                            min-width: 250px;
                            @include mq-max(2xs){
                                min-width: 220px;
                            }

                        }
                    }
                }
                i{
                    --Iconsax-Size: 18px;
                    --Iconsax-Color: #00162E;
                }
            }
        }
        .form-control{
            padding-left: 16px;
            border: 2px dashed $gray-color;
            font-size: 16px;
            font-weight: 500;
            
        }

        .pattern-input{
            border: 1px dashed $primary-color;
            color: $primary-color;
            background-color: rgba($primary-color, 0.10);
            &:hover, &:focus{
                background-color: rgba($primary-color, 0.10); 
                color: $primary-color;
            }
        }
    }
}

.pattern-btn{
    background-color: $white;
    border: 2px dashed $gray-color;
    color: $primary-color;
    font-size: 14px;
    font-weight: 500;
    padding: calc(7px + (16 - 7) * ((100vw - 320px) / (1920 - 320))) 16px;
    border-left: none;
    margin-left: 0 !important;
    position: relative;
    border-radius: 8px;
    &:after, &:before{
        content: "";
        position: absolute;
        pointer-events: none;
        border: solid transparent;
        display: block;
        border-width: 1px;            
        width: 12px;
        height: 12px;            
        border-radius: 100%;
        background-color: $section-bg;
        z-index: 5;
    }

    &:after{
        border-right-color: $gray-color;
        border-left-color: $gray-color;
        border-top-color: $gray-color;
        left: -7px;
        bottom: -4px;
    }
    &:before{
        border-right-color: $gray-color;
        border-left-color: $gray-color;
        border-bottom-color: $gray-color;
        left: -7px;
        top: -4px;
    }
}

.form-control.pattern-input{
    border: 1px dashed $primary-color !important; 
    color: $primary-color;
    background-color: rgba($primary-color, 0.10);
    border-radius: 8px;
    &:hover, &:focus{
        background-color: rgba($primary-color, 0.10); 
        color: $primary-color;
    }
}
.pattern-btn-1{
    background-color: $white;
    border: 1px dashed $primary-color;
    background-color: rgba($primary-color, 0.10);
    color: $primary-color;
    font-size: 14px;
    font-weight: 500;
    padding: calc(7px + (16 - 7) * ((100vw - 320px) / (1920 - 320))) 16px;
    border-left: none;
    margin-left: 0 !important;
    position: relative;
    border-radius: 8px;
    &:after, &:before{
        content: "";
        position: absolute;
        pointer-events: none;
        border: dashed transparent;
        display: block;
        border-width: 1px;            
        width: 12px;
        height: 12px;            
        border-radius: 100%;
        background-color: $section-bg;
        z-index: 5;
    }

    &:after{
        border-right-color: $primary-color;
        border-left-color: $primary-color;
        border-top-color: $primary-color;
        left: -7px;
        bottom: -4px;
    }
    &:before{
        border-right-color: $primary-color;
        border-left-color: $primary-color;
        border-bottom-color: $primary-color;
        left: -7px;
        top: -4px;
    }
}

.view{
    margin-top: 8px;
    background-color: rgba($primary-color, 0.10);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 20px;
    span{
        color: $light-color;
        font-size: 16px;
        line-height: 20px;
    }
    .value{
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        color: $primary-color;
    }
    .btn{
        padding: calc(4px + (10 - 4) * ((100vw - 320px) / (1920 - 320))) calc(12px + (24 - 12) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));

        i{
            svg{
                width: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                height: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }
}

.price{
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;  
    color: $primary-color;                 
}
.discount{
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: $danger-color;
    white-space: nowrap;
}

.bill-summary, .payment-summary{
    .charge, .total{
        background-color: $white;
        border: 1px solid $gray-color;
        border-radius: 8px;
        padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        display: flex;
        flex-direction: column;
        gap: 16px;
        @include mq-max(sm){
            gap: 6px;
        }
        &:before{
            display: none;
        }
        p{
            margin: 0;
            font-size: 16px;               
        }
        li{
            @include flex_common ($dis: flex, $align: center, $justify: space-between);
            gap: 4px;
            margin: 0;
            @include mq-max(sm){
                flex-direction: column;
                align-items: start;
                gap: 2px;
            }
        }
    }
    .charge{
        p{
            color: $light-color;
            @include flex_common_1 ($dis: flex, $align: center);
            gap: 6px;
            span{
                color: $light-color;
                font-weight: 400;
            }
        }
        span{
            color: $title-color;
            font-weight: 500;
            font-size: 16px;
        } 
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .total{
        position: relative;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        p{
            color: $title-color;
            font-weight: 500;
        }
        span{
            color: $primary-color;
            font-weight: 700;
            font-size: 20px;
        }
        &:after, &:before{
            content: "";
            position: absolute;
            pointer-events: none;
            border: solid transparent;
            display: block;
            border-width: 1px;            
            width: 10px;
            height: 15px;
            top: -8px;
            border-radius: 100%;
            background-color: $section-bg;
        }

        &:after{
            border-left-color: $gray-color;
            border-top-color: $gray-color;
            border-bottom-color: $gray-color;
            right: -2px;
        }
        &:before{
            border-right-color: $gray-color;
            border-top-color: $gray-color;
            border-bottom-color: $gray-color;
            left: -2px;
        }
    }
}

.edit{
    --Iconsax-Size: 20px;
    --Iconsax-Color: #00162E;
    background-color: $section-bg;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    @include flex_common ($dis: flex, $align: center, $justify: center);
}

.delete{
    --Iconsax-Size: 20px;
    --Iconsax-Color: #FF4B4B;
    background-color: rgba($danger-color, 0.10);
    border-radius: 100%;
    height: 40px;
    width: 40px;
    @include flex_common ($dis: flex, $align: center, $justify: center);
}

.servoice-info-modal{
    border: none;
    background-color: unset;
    padding: 0;
    i{
        --Iconsax-Size: 16px;
        --Iconsax-Color: #575756;
    }
}

.coupon-list{
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 670px;
    overflow: auto;
}

.coupon-item{
    border: 1px solid $gray-color;
    border-radius: 12px;
    .coupon-content{
        position: relative;
        gap: 8px;
        padding: 20px;
        text-align: left;
        @include flex_common ($dis: flex, $align: center, $justify: space-between);
        h5{
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 6px;

        }
        p{
            color: $light-color !important;
            line-height: 18px;
            margin: 0;
            span{
                font-weight: 700;
            }
        }
        .percent{
            font-size: 18px;
            width: 80px;
            font-weight: 700;
            line-height: 19px;
            padding-left: 30px;
            border-left: 1px dashed rgba($title-color, 0.40);
        }
        &:after, &:before{
            content: "";
            position: absolute;
            pointer-events: none;
            border: solid transparent;
            display: block;
            border-width: 1px;            
            width: 12px;
            height: 12px;            
            border-radius: 100%;
            background-color: $white;
            border-right-color: $gray-color;
            border-left-color: $gray-color;
            border-bottom-color: $gray-color;
            top: -3px;
        }

        &:after{
            right: 95px;
        }
        &:before{
            left: 40px;
        }
    }
    .coupon-footer{
        background-color: rgba($primary-color, 0.10);
        display: flex;
        justify-content: space-between;
        gap: 4px;
        padding: 20px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding-top: 35px;

        p{
            margin: 0;
            color: $light-color !important;
            span{
                color: $title-color;
            }
        }

        .use-code{
            @include flex_common_1 ($dis: flex, $align: center);
            gap: 4px;
            color: $primary-color;
            font-weight: 500;
            line-height: 18px;
            i{
                --Iconsax-Size: 18px;
                --Iconsax-Color: #575756;
               }
        }
    }
}
.circle{
    background-image: url(../../assets/images/circle.png);
    background-position: top left;
    background-repeat: repeat-x;
    background-size: contain;
    height: 22px;
    width: 100%;
    margin: -14px 0;
    position: relative;
    z-index: 1;
}

.payment{
    background-color: $section-bg;
    border-radius: 15px;
    .payment-header{
        border-bottom: 1px solid $gray-color;
        padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        position: relative;
        @include flex_common ($dis: flex, $align: start, $justify: space-between);
        span{
            color: $light-color;
            margin-top: 4px;
            font-size: 16px;
        }
        .back-icon{
            --Iconsax-Size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            --Iconsax-Color: #00162E;
            position: absolute;
            top: calc(14px + (21 - 14) * ((100vw - 320px) / (1920 - 320)));;
            left: 18px;
        }

        @include mq-max(md){
            flex-direction: column;
            align-items: start;
            .edit-option{
                padding-left: 24px;
            }
        }
    }
    .payment-body{
        padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        overflow: auto;
        height: 450px;
    
        .payment-options{
            .payment-option{
                background-color: $white;
                border: 1px solid $gray-color;
                border-radius: 10px;
                padding: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
                .payment-title{
                    @include flex_common_1 ($dis: flex, $align: center);
                    gap: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
                    a{
                        color: $title-color;
                    }
                    h4{
                        line-height: 23px;
                        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
                        &.wallet{
                            &:hover{
                                color: $primary-color;
                                text-decoration: underline;
                            }
                        }
                    }
                    p{
                        margin: 0;
                        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
                        color: $light-color;
                        line-height: 20px;
                        span{
                            font-weight: 700;
                            color: $success-color;
                        }
                    }

                    .payment-img{
                        min-width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
                        width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
                        height: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
                        background-color: $section-bg;
                        border-radius: 8px;
                        @include flex_common ($dis: flex, $align: center, $justify: center);
                        img{
                            width: calc(22px + (25 - 22) * ((100vw - 320px) / (1920 - 320)));
                            height: calc(22px + (25 - 22) * ((100vw - 320px) / (1920 - 320)));
                            object-fit: contain;                    
                        }
                        .active{
                            display: none;
                        }
                        .deactive{
                            display: block; 
                        }
                    }
                }
                


                .form-check{
                    gap: 4px;
                    @include flex_common ($dis: flex, $align: center, $justify: space-between);
                    flex-direction: row-reverse;
                    .form-radio-input{
                        &:checked{
                            ~.payment-title{
                                .payment-img{
                                    background-color: rgba($primary-color, 0.10);
                                    .active{
                                        display: block;
                                    }
                                    .deactive{
                                        display: none;
                                    }
                                }
                                a{
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
        }
       
    }
    .payment-footer{
        padding: 20px;
        border-top: 1px solid $gray-color;
        .btn{
            width: max-content;
            padding: 10px 46px;
            padding: calc(4px + (10 - 4) * ((100vw - 320px) / (1920 - 320))) calc(20px + (46 - 20) * ((100vw - 320px) / (1920 - 320)));
            font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        }
    }
    .wallet-body{
        padding: 20px;
        .total-balance{
            background-color: $primary-color;
            background-image: url(../../assets/images/banner/3.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            z-index: 1;
            border-radius: 10px;
            padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: 20px;
            p{
                color: rgba($white, 0.70);
                font-size: 16px;
                font-weight: 500;
                margin: 0;
                line-height: 20px;
            }
            h3{
                font-weight: 700;
                color: $white;
                line-height: 31px;
            }
        }
        .wallet-history{
            overflow: auto;
            height: 660px;
            .history-list{
                border: 1px solid $gray-color;
                padding: 16px;
                border-radius: 10px;
                background-color: $white;
                @include flex_common ($dis: flex, $align: center, $justify: space-between);
                gap: 4px;
                box-shadow: 0px 4px 4px 0px rgba($title-color, 0.06);
                
                .status{
                    font-size: 18px;
                    font-weight: 500;
                    margin: 0;
                    line-height: 23px;
                }

                .date{
                    font-size: 16px;
                    color: $light-color;
                    line-height: 20px;
                }

                .balance{
                    font-size: 18px;
                    font-weight: 700;
                    margin: 0;
                    line-height: 23px;
                }

                .credit, .debit{
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                }

                .credit{
                    color: $success-color;
                }

                .debit{
                    color: $danger-color;
                }

                @include mq-max(md){
                    flex-direction: column;
                    align-items: start;
                }

            }
        }
        .select-date{
            @include flex_common ($dis: flex, $align: center, $justify: space-between);
            gap: 10px;
            padding-bottom: 24px;
            margin: 0;
            h4{
                color: $title-color;
                font-weight: 500;
            }
            .date-pick{
                @include flex_common_1 ($dis: flex, $align: center);
                gap: 12px;
                label{
                    white-space: nowrap;
                }
                .input-group{
                    input{
                        border-radius: 8px;
                        font-size: 13px;
                        min-width: 232px;                    
                    }
                }
            }
            @include mq-max(md){
                gap: 10px;
                flex-direction: column;
                align-items: start;
                justify-content: unset;
            }
        }
    }
}