/**=====================
    Form scss
==========================**/

.form-control {
    border: 1px solid rgba($white, 0.10%);
    background-color: rgba($white, 0.10%);
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
    color: $white;
    padding: calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320))) 16px;
    width: 100%;
    border-radius: 8px;

    ::placeholder {
        color: $gray-color;
    }

    &.first-child {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }

    &.last-child {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
    }

    &:focus,
    &:hover {
        background-color: rgba($white, 0.10%);
        color: $title-color;
        box-shadow: none;
        border: 1px solid rgba($white, 0.10%);
    }

    &.form-control-gray {
        background-color: $section-bg;
        color: $title-color;

        &:focus,
        &:hover {
            background-color: $section-bg;
            color: $title-color;
            box-shadow: none;
        }

        ::placeholder {
            color: $gray-color;
        }
    }

    &.form-control-white {
        background-color: $white;
        color: $title-color;
        border-radius: 8px;
        // border: 1px solid $gray-color;

        &:focus,
        &:hover {
            background-color: $white;
            color: $title-color;
            box-shadow: none;
        }

        ::placeholder {
            color: $gray-color;
        }
    }
}

textarea {
    &.form-control {
        min-height: calc(39px + (46 - 39) * ((100vw - 320px) / (1920 - 320)));
        ;
    }
}

.comment-section {
    textarea {
        &.form-control {
            min-height: 110px;
        }
    }
}

.form-group {
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }

    label {
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 500;
        line-height: 23px;
        margin-bottom: 8px;
        position: relative;
    }
}

.form-check {
    padding: 0;
    min-height: unset;
    @include flex_common_1 ($dis: flex, $align: center);
    gap: 12px;

    .form-check-input,
    .form-radio-input {
        cursor: pointer;
        position: relative;
        border: none;
        width: 20px;
        height: 20px;
        box-shadow: none;
        background-color: transparent;
        @include flex_common_1 ($dis: flex, $align: center);
        margin-top: 0;

        &:focus {
            box-shadow: none;
        }

        &:before {
            transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
            transform: rotate(-45deg) scale(0, 0);
            content: "";
            position: absolute;
            left: 3px;
            top: 5px;
            z-index: 1;
            width: 0.75rem;
            height: 0.4rem;
            border: 2px solid $white;
            border-top-style: none;
            border-right-style: none;
        }

        &:after {
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            width: 20px;
            height: 20px;
            background: $white;
            border: 2px solid $gray-color;
            cursor: pointer;
            border-radius: 4px;
        }

        &:checked {
            &:before {
                transform: rotate(-45deg) scale(1, 1);
            }

            &:after {
                background-color: $primary-color;
                border-color: $primary-color;
            }
        }
    }

    .form-radio-input {
        margin: 0;
        padding: 0;
        height: 10px;

        &:after {
            border-radius: 100%;
            left: 0;
            top: -5px;
        }

        &:before {
            border: none;
            background-color: $primary-color;
            border-radius: 100%;
            width: 10px;
            height: 10px;
            top: 0px;
            left: 5px;

        }

        ~label {
            color: $light-color;
        }

        &:checked {
            &:after {
                border: 6px solid rgba($primary-color, 0.12%);
                background-color: $white;
            }

            ~label {
                font-weight: 500;
                color: $title-color;
            }
        }

        &.solid {
            &:checked:after {
                border: 1px solid $primary-color;
            }
        }
    }

    p {
        font-size: 16px;
    }

    label {
        font-size: 14px;
    }
}

.form-select {
    background-color: $section-bg;
    border: none;
    color: $title-color;
    font-size: 16px;
    padding: 8px 70px 8px 12px;
    border-radius: 6px;

    &:focus {
        box-shadow: none;
    }

    &.form-select-white {
        background-color: $white;
        color: $light-color;
        border-radius: 8px;
        padding: 10px 46px;

        &:focus,
        &:hover {
            background-color: $white;
            color: $light-color;
            box-shadow: none;
        }
    }
}

.input-group {
    .form-control {
        padding: calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320))) 16px calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320))) 46px;
    }

    &.phone-field {
        gap: 10px;

        .contact-btn {
            border-radius: 8px;
        }

    }
}

.search-form {
    margin-top: 30px;
    width: 30%;
    margin-inline: auto;
    padding: 10px;
    border-radius: 40px !important;
    border: 1px solid rgba($white, 0.20%);

    .form-control {
        border-radius: 40px;
        color: $white;
    }

    ::placeholder {
        color: $light-color;
    }

    i {
        --Iconsax-Size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        --Iconsax-Color: #808B97;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
    }

    @include mq-max(3xl) {
        width: 40%;
    }

    @include mq-max(lg) {
        width: 70%;
        padding: 4px;
    }

    @include mq-max(sm) {
        width: 90%;
        margin-top: 16px;
    }
}

.avatar-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .avatar {
        width: 40px;
        height: 40px;

        img {
            border-radius: 100%;
            width: 100%;
            height: 100%;
        }

        &:first-child {
            margin-right: -20px;
        }

        &:nth-child(2) {
            width: 45px;
            height: 45px;
            position: relative;
            z-index: 1;
        }

        &:last-child {
            margin-left: -20px;
        }
    }
}