/**=====================
    Rating scss
==========================**/

.star-rating {
    @include flex_common ($dis: flex, $align: center, $justify: start);
    flex-wrap: wrap;
    gap: 7px;
    flex-direction: row;
    font-size: 1.5em;

    input {
        display: none;
    }

    label {
        @include flex_common_1 ($dis: flex, $align: center);
        background-color: $white;
        color: $light-color;
        gap: 6px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid $gray-color;
        line-height: 0;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;

        i {
            --Iconsax-Size: 18px;
            --Iconsax-Color: #808B97;
        }

        &:hover {
            background-color: $primary-color;
            color: #fff;

            i {
                --Iconsax-Color: #fff;
            }
        }
    }

    :checked {
        ~label {
            background-color: $primary-color;
            color: $white;
            border-color: $primary-color;

            i {
                --Iconsax-Color: #fff;
            }
        }
    }
}

.reviews {
    background-color: $white;
    padding: 16px;
    border-radius: 8px;
    @include flex_common ($dis: flex, $align: start, $justify: space-between);
    gap: 12px;
    margin-bottom: 16px;

    &:last-child {
        margin: 0;
    }

    .person-detail {
        @include flex_common_1 ($dis: flex, $align: center);
        gap: 6px;
        color: $title-color;

        img {
            border-radius: 100%;
            height: 45px;
            width: 45px;
        }

        p {
            margin: 0;
            font-size: 16px;
            // color: $title-color;
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            text-overflow: ellipsis;
        }

        h5 {
            line-height: 1.5;
            font-weight: 500;
        }
    }

    .rating {
        .rate {
            justify-content: end;
            color: $title-color;

            small {
                font-size: 16px;
            }
        }
        .overview-list{
            li{
                white-space: nowrap;
            }
        }
        @include mq-max(sm){
            margin-left: 50px;
        }
    }
    @include mq-max(sm){

        flex-direction: column;
        align-items: start;
        justify-content: unset;
        gap: 4px;

        .rating{
            margin-left: 50px;
            @include flex_common_1 ($dis: flex, $align: center);
            flex-direction: row-reverse;
            gap: 10px;
            .rate{
                margin: 0;
            }
        }
    }
}

.rating-bars {
    background-color: $white;
    border-radius: 8px;
    padding: 16px;
    white-space: nowrap;
    display: flex;
    gap: 10px;
    flex-direction: column;
    
    .rating-bar {
        @include flex_common_1 ($dis: flex, $align: center);
        gap: 8px;
        text-align: left;
       
        .bar {
            width: 100%;
            background-color: $section-bg;
            border-radius: 4px; 
            .bar-item {
                height: 8px;
                background-color: $warning-color;
                border-radius: 4px;
                &-5{
                    width: 84%;
                } 
                &-4{
                    width: 30%;
                }
                &-3{
                    width: 12%;
                }
                &-2{
                    width: 6%;
                }
                &-1{
                    width: 3%;
                }
            }
        }
        .left{
            min-width: 40px;
        }
        .right{
            color: $light-color;
            text-align: left;
            min-width: 40px;
        }
    }
}