/**=====================
     About Us scss
==========================**/



.expert-section {
    position: relative;
    background-image: url(../../assets/images/bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0;    
}
.expert-content{

    .dark-card{
        .card-title{
            a{
                color: $white;
                &:hover{
                    color: $primary-color;
                }
            }
        }
    }

    .dark-card, .gray-card {
        overflow: visible;
        margin-top: 60px;

        .dark-card-img, .gray-card-img{
            margin-bottom: 24px;
            text-align: center;

            img {
                margin-top: -60px;
                border-radius: 100%;
                width: 130px;
                height: 130px;
                object-fit: cover;
                border: 7px solid rgba($white, 0.06%);
                 @include mq-max(sm) {
                    width: 110px;
                    height: 110px;
                }

                &.like{
                    margin: 0;
                    border: none;
                    border-radius: 0;
                }
            }
            .favourite{
                top: 20px;
                right: 20px;
                left: unset;
            }
        }

        .card-body {
            padding: 0 15px;

            .card-title {
                @include flex_common ($dis: flex, $align: center, $justify: center);
                gap: 12px;
                color: $white;

                h4 {
                    font-size: 20px;
                    font-weight: 500;
                }

                small {
                    font-size: 14px;
                }
            }

            .card-detail {
                padding: 24px 0;
                margin-top: 24px;
                border-top: 2px dashed rgba($white, 0.10%);

                p {
                    font-size: 16px;
                    line-height: 24px;
                    width: 86%;
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }
    }

    .gray-card {
        .card-body {
            color: $light-color;

            .card-title {
                color: $title-color;
                a{
                    color: $title-color;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }

            .card-detail {
                border-top: 2px dashed $gray-color;
            }
        }
    }
}

.reviews-main{
    background-color: $section-bg;
    border-radius: 10px;
    padding: 20px;
    h6{
        font-weight: 500;
        line-height: 1.5;
    }
    .overview-list{
        li{
            color: $light-color;
            font-size: 14px;
        }
    }
}

.location {
    @include flex_common ($dis: flex, $align: center, $justify: center);
    gap: 6px;

    i {
        --Iconsax-Size: 18px;
        --Iconsax-Color: #808B97;
    }

    h5 {
        line-height: 20px;
        color: $light-color;
    }
}

.filter{
    .card{
        background-color: $section-bg;
        .card-header{
            padding: 25px 20px;
            background-color: transparent;
            border-bottom: 1px solid $gray-color;
            @include flex_common ($dis: flex, $align: center, $justify: space-between);
            h3{
                font-weight: 700;
            }
        }
        .card-body{
            overflow: auto;
            height: 903px;
            .form-group{
                @include flex_common_1 ($dis: flex, $align: center);
                gap: 8px;
            }
        }
    }
    .provider-card{
        background-color: $section-bg;
        border-radius: 10px;
        .favourite{
            top: 30px;
            right: 30px;
            left: unset;
        }
        .provider-detail{
            .provider-content{
                border: none;
                .profile-bg{
                    background-color: $gray-color;
                }
                .profile{
                    .img{
                        border-radius: 6px;
                        width: 110px;
                        height: 110px;
                    }
                }
                i {
                    --Iconsax-Size: 16px;
                    --Iconsax-Color: #808B97;
                }
                .delivered{
                    background-color: $white;
                    padding: 13px 16px;
                    border-radius: 6px;
                    width: 100%;
                    @include flex_common ($dis: flex, $align: center, $justify: space-between);
                    margin: 25px 0 10px;
                    span{
                        font-size: 16px;
                    }
                    small{
                        font-size: 18px;
                        font-weight: 700;
                        color: $primary-color;
                    }
                }
                .profile-detail{
                    p{
                        color: $title-color;
                        text-align: left;
                    }
                }

                .profile-info{
                    background-color: $white;
                    padding: 16px;
                    p{
                        color: $title-color;
                        text-align: start;
                    }
                    label{
                        @include flex_common_1 ($dis: flex, $align: center);
                        gap: 6px;
                        font-size: 14px;
                    }

                    i {
                        --Iconsax-Size: 18px;
                    }
                    .btn-solid-gray{
                        font-size: 14px;
                        border-radius: 6px;
                        padding: 6px 13px;
                    }
                }
            }
        }
    }
}

.provider-detail{
    .provider-content{
        border-radius: 12px;
        border: 1px solid $gray-color;
        padding: 20px;

        .profile-bg{
            background-color: $section-bg;
            height: 70px;
            border-radius: 12px;
        }
        i {
            --Iconsax-Size: 16px;
            --Iconsax-Color: #FFC412;
        }
        .profile{
            @include flex_common_1 ($dis: flex, $align: center);
            flex-direction: column;
            margin-top: -45px;
            .img{
                border-radius: 100%;
                width: 80px;
                height: 80px;
                border: 4px solid $white;
            }
            .rate{
                font-size: 16px;
            }
        }
        .profile-detail{
            margin-top: 10px;
            ul{
                display: flex;
                flex-direction: column;
                margin-bottom: 8px;
                li{
                    @include flex_common ($dis: flex, $align: center, $justify: space-between);
                    gap: 4px;
                    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                    border-bottom: 1px solid $gray-color;
                    padding: 10px 0;
                    &:last-child{
                        border: none;
                    }
                    @include mq-max(sm){
                        flex-direction: column;
                        align-items: start;
                        gap: 0;
                    }
                }
            }
        }
        .badge{
            padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 10px;
            width: 100%;
            text-align: start;
            @include flex_common_1 ($dis: flex, $align: center);
            gap: 14px;
            margin-bottom: 16px;
        }
        p{
            font-size: 16px;
            color: $light-color;
            line-height: 26px;
            text-align: center;
        }
        .badge-img{
            width: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));
            height: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));
        }
    }
}

.image-grp{
    position: relative;
    width: 90%;
    margin-inline: auto;
    @include mq-max(xl){
        width: 100%;
    }
}


.application-section,
.service-provider-section {
    position: relative;

    .row {
        align-items: center;
    }

    .position-absolute {
        position: absolute;
        bottom: calc(50px + (198 - 55) * ((100vw - 320px) / (1920 - 320)));
        right: calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)));
        @include mq-max(4xl){
            bottom: calc(75px + (168 - 75) * ((100vw - 320px) / (1920 - 320)));
            right: calc(10px + (50 - 10) * ((100vw - 320px) / (1920 - 320)))
        }
        @include mq-max(3xl){
            bottom: calc(75px + (168 - 75) * ((100vw - 320px) / (1920 - 320)));
            right: calc(10px + (50 - 10) * ((100vw - 320px) / (1920 - 320)))
        }
        @include mq-max(2xl){
            bottom: calc(75px + (148 - 75) * ((100vw - 320px) / (1920 - 320)));
            right: 10px;
        }
        @include mq-max(xl){
            bottom: 0;
            right: 0px;
        }
        @include mq-max(sm){
           display: none;
        }
    }

    .gif-content {
        box-shadow: -8px -8px 22px #5250505e;
        border-radius: 10px;
        color: $white;
        padding: 16px;
        background: linear-gradient(180deg, rgba(139, 84, 255, 1) 0%, rgba(84, 101, 255, 1) 100%);
        width: 140px;
        text-align: center;

        p {
            margin: 0;
            font-size: 12px;

        }

        h6 {
            border-top: 1px dashed rgba($white, 0.1%);
            margin-top: 4px;
            padding-top: 4px;
            position: relative;
            height: 10px;

            &:before {
                content: "User";
                position: absolute;
                left: 0;
                right: 0;
                animation: textChange infinite 2.5s;
                animation-delay: 2.5s;
            }
        }
    }

    .spark {
        position: absolute;
        top: -37px;
        left: 60%;
        transform: translateX(-60%);
    }

    .section-wrap {
        .image-1 {
            position: absolute;
            z-index: -1;
            bottom: 120px;
            right: -45px;
            animation: mover 2s infinite alternate;
            animation-delay: 1s;
            width: 140px;
            overflow: hidden;
            @include mq-max(sm){
                display: none;
            }
        }

        .image-2 {
            position: absolute;
            animation: mover 2s infinite alternate;
            z-index: -1;
            top: 210px;
            left: 90px;
            animation-delay: 2s;
            height: 120px;
            @include mq-max(lg){
                display: none;
            }
        }

        .vector-1,
        .girl-img {
            margin: 0 auto;
            display: flex;
            width: 100%;
        }

        .app-gif {
            position: absolute;
            top: 0;
            left: 20%;
            height: 100%;
        }

        
    }
}

.app-install {
    margin-top: 50px;
    @include flex_common_1 ($dis: flex, $align: center);
    gap: 16px;
    
    img {
        height: 60px;
    }
    @include mq-max(lg){
        gap: 10px;
        margin-top: 30px;
        img{
            height: calc(45px + (60 - 45) * ((100vw - 320px) / (1920 - 320)));
        }
    }
}

.content-detail {
    color: $light-color;

    p {
        font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        line-height: 30px;
        margin-bottom: 16px;
        @include mq-max(sm){
            line-height: 24px;
            margin-bottom: 8px;
        }
    }

    .item-lists {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-left: 30px;
        @include mq-max(sm){
            gap: calc(0px + (12 - 0) * ((100vw - 320px) / (1920 - 320)));
        }

        li {
            font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            position: relative;
            line-height: 26px;

            &:before {
                position: absolute;
                content: "";
                width: 5px;
                height: 5px;
                background-color: $light-color;
                top: 12px;
                left: -16px;
                border-radius: 100%;

            }
        }
    }
}

.chart-img{
    position: absolute;
    bottom: 55px;
    left: 120px;
    border-radius: 13px;
    animation: mover 2s infinite alternate;
    animation-delay: 1s;
    @include mq-min(lg){
        width: calc(120px + (200 - 120) * ((100vw - 992px) / (1920 - 992)));
    }

    @include mq-max(xl){
        left: 50px;
    }

    @include mq-max(lg){
        left: calc(30px + (300 - 30) * ((100vw - 320px) / (1920 - 320)));
        bottom: calc(11px + (205 - 11) * ((100vw - 320px) / (1920 - 320)));
        width: calc(121px + (200 - 121) * ((100vw - 320px) / (1920 - 320)));
    }
}

.group-img{
    position: absolute;
    top: 120px;
    right: 30px;
    border-radius: 13px;
    animation: mover 2s infinite alternate;
    animation-delay: 1s;
    @include mq-min(lg){
        width: calc(120px + (200 - 120) * ((100vw - 992px) / (1920 - 992)));
    }
    @include mq-max(xl){
        right: 0px;
    }
    @include mq-max(lg){
        right: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
        top: calc(90px + (210 - 90) * ((100vw - 320px) / (1920 - 320)));
        width: calc(110px + (200 - 110) * ((100vw - 320px) / (1920 - 320)));
    }
}

.service-provider-section {
    position: relative;
    background-image: url(../../assets/images/bg.png);
    background-color: $section-bg;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0;

    .item-lists {
        margin: 0 !important;

        .item-list {
            &:before {
                display: none;
            }

            i {
                --Iconsax-Size: 18px;
                --Iconsax-Color: #575756;
            }
        }
    }

    .section-wrap {
        .image-1 {
            bottom: 50px;
            right: 80px;
        }

        .image-2 {
            top: 60px;
            left: 70px;
        }
    }

    .btn {
        border-radius: 13px;
        margin-top: 50px;
        width: max-content;

        @include mq-max(lg){
            margin-top: 30px;
        }
    }
}