:root{
    --theme-color: #575756;
    --theme-color-rgb: 82, 99, 255;
}

$primary-color: #575756;
$secondary-color: #ff7456;
$success-color: #27AF4D;
// $warning-color: #FFC412;
$warning-color: #FDB448;
$danger-color: #FF4B4B;
$info-color: #48bffd;
$white: #ffffff;
$black: #000000;
$title-color: #00162E;
$light-color: #808B97;
$gray-color: #E5E8EA;
$section-bg: #F5F6F7;
$login-card: 530px;
$login-br: 15px; 

// font family
$dm-sons: 'DM Sans'; 

// breakpoints
$min-breakpoints: (md:768px, lg: 992px,
    xl: 1200px,
    2xl: 1366px,
);

$max-breakpoints: (2xs: 360px,
    xs: 480px,
    sm: 575px,
    md: 767px,
    lg: 991px,
    xl: 1199px,
    2xl: 1366px,
    3xl: 1460px,
    4xl: 1660px,
);
