/**=====================
    Ration scss
==========================**/

.ratio_18 {
    .bg-size {
        &:before {
            padding-top: 18%;
            content: "";
            display: block;
        }
    }
} 

.ratio_20 {
    .bg-size {
        &:before {
            padding-top: 20%;
            content: "";
            display: block;
        }
    }
} 


.ratio_24 {
    .bg-size {
        &:before {
            padding-top: 24%;
            content: "";
            display: block;
        }
    }
}

.ratio_36 {
    .bg-size {
        &:before {
            padding-top: 36%;
            content: "";
            display: block;
        }
    }
}

.ratio_40 {
    .bg-size {
        &:before {
            padding-top: 40%;
            content: "";
            display: block;
        }
    }
}

.ratio_45 {
    .bg-size {
        &:before {
            padding-top: 45%;
            content: "";
            display: block;
        }
    }
}

.ratio_46 {
    .bg-size {
        &:before {
            content: "";
            padding-top: 46%;
            display: block;
        }
    }
}

.ratio_47 {
    .bg-size {
        &:before {
            padding-top: 47.8%;
            content: "";
            display: block;
        }
    }
}

.ratio_70 {
    .bg-size {
        &:before {
            padding-top: 70%;
            content: "";
            display: block;
        }
    }
}

.ratio2_1 {
    .bg-size {
        &:before {
            padding-top: 50%;
            content: "";
            display: block;
        }
    }
}

.ratio2_2 {
    .bg-size {
        &:before {
            padding-top: 56%;
            content: "";
            display: block;
        }
    }
}

.ratio2_3 {
    .bg-size {
        &:before {
            padding-top: 60%;
            content: "";
            display: block;
        }
    }
}

.ratio-68 {
    .bg-size {
        &:before {
            padding-top: 68%;
            content: "";
            display: block;
        }
    }
}

.ratio3_2 {
    .bg-size {
        &:before {
            padding-top: 66.66%;
            content: "";
            display: block;
        }
    }
}

.ratio_landscape {
    .bg-size {
        &:before {
            padding-top: 75%;
            content: "";
            display: block;
        }
    }
}

.ratio-80 {
    .bg-size {
        &:before {
            padding-top: 80%;
            content: "";
            display: block;
        }
    }
}

.ratio-85 {
    .bg-size {
        &:before {
            padding-top: 85%;
            content: "";
            display: block;
        }
    }
}

.ratio_blog-list {
    .bg-size {
        &:before {
            padding-top: 88.6%;
            content: "";
            display: block;
        }
    }
}

.ratio_square {
    .bg-size {
        &:before {
            padding-top: 100%;
            content: "";
            display: block;
        }
    }
}

.ratio_94 {
    .bg-size {
        &:before {
            padding-top: 94%;
            content: "";
            display: block;
        }
    }
}

.ratio_115 {
    .bg-size {
        &:before {
            padding-top: 115%;
            content: "";
            display: block;
        }
    }
}

.ratio_125 {
    .bg-size {
        &:before {
            padding-top: 125%;
            content: "";
            display: block;
        }
    }
}

.ratio_124 {
    .bg-size {
        &:before {
            padding-top: 124.7777778%;
            content: "";
            display: block;
        }
    }
}

.ratio_asos {
    .bg-size {
        &:before {
            padding-top: 127.7777778%;
            content: "";
            display: block;
        }
    }
}

.ratio_asos_1 {
    .bg-size {
        &:before {
            padding-top: 136.777778%;
            content: "";
            display: block;
        }
    }
}


.ratio_portrait {
    .bg-size {
        &:before {
            padding-top: 150%;
            content: "";
            display: block;
        }
    }
}

.ratio_163 {
    .bg-size {
        &:before {
            padding-top: 163%;
            content: "";
            display: block;
        }
    }
}

.ratio1_2 {
    .bg-size {
        &:before {
            padding-top: 200%;
            content: "";
            display: block;
        }
    }
}

.b-top {
    background-position: top !important;
}

.b-bottom {
    background-position: bottom !important;
}

.b-center {
    background-position: center !important;
}

.b_size_content {
    background-size: contain !important;
    background-repeat: no-repeat;
}