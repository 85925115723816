/**=====================
     Pagination scss
==========================**/

.pagination-main{
    border-top: 1px solid $gray-color;
    padding-top: 20px;
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    @include mq-max(sm){
        justify-content: center;
    }
    label{
        color: $light-color;
        @include mq-max(sm){
            display: none;
        }
    }
    i {
        --Iconsax-Size: 18px;
        --Iconsax-Color: #00162E;
    }
    .pagination{
        @include flex_common_1 ($dis: flex, $align: center);
        gap: 8px;
        .page-item{
            .page-link{
                @include flex_common ($dis: flex, $align: center, $justify: center);
                color: $light-color;
                width: 30px;
                height: 30px;
                border: none;
                border-radius: 100%;
                &:focus{
                    border: none;
                    outline: none;
                    box-shadow: none;
                }
                span{
                    line-height: 1;
                }
            }
            &.active{
                .page-link{
                    background-color: $primary-color;
                    color: $white;
                }
            }
        }
    }
}