/**=====================
    Header scss
==========================**/

header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;

    &.active {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        background-color: $black;
        box-shadow: 0 8px 10px rgba($title-color, 0.05);

        .container-fluid-xl {
            padding: 0 calc(12px + (100 - 12) * ((100vw - 320px) / (1920 - 320)));
        }

        .navbar {
            .onhover-show-div {
                background-color: $white !important;

                li {
                    color: rgba($title-color, 0.6) !important;

                    a {
                        color: rgba($title-color, 0.6) !important;
                    }

                    i {
                        --Iconsax-Color: #00162e99 !important;
                    }

                    &:hover {
                        color: $title-color !important;

                        a {
                            color: $title-color !important;
                        }

                        i {
                            --Iconsax-Color: #00162E !important;
                        }
                    }
                }
            }
        }
    }

    .custom-navbar {
        .logo-content {
            @include flex_common_1 ($dis: flex, $align: center);
            gap: calc(4px + (24 - 4) * ((100vw - 320px) / (1920 - 320)));

            .navbar-toggler-icon {
                filter: invert(1);
            }
        }

        i {
            --Iconsax-Size: 18px;
            --Iconsax-Color: White;
        }

        .navbar-brand {
            margin: 0;
            padding: 0;
            font-size: 0;

            img {
                @include pseudowh($width: calc(80px + (116 - 80) * ((100vw - 320px) / (1920 - 320))), $height: auto);
            }
        }

        .location-dropdown {
            border: 1px solid rgba($white, 0.10%);
            background-color: #1d2537;
            padding: 7px 9px;
            border-radius: 100%;
            @include flex_common_1 ($dis: flex, $align: center);
            gap: 6px;
            padding: 5px 8px;
            border-radius: 30px;

            .location-btn {
                background: transparent;
                color: white;
                border: none;
                line-height: 1;
                padding: 0;
                @include flex_common_1 ($dis: flex, $align: center);
                gap: 12px;

                .loaction-svg {
                    --Iconsax-Size: 24px;
                    --Iconsax-Color: White;

                }

                .arrow {
                    ---Iconsax-Size: 18px;
                    --Iconsax-Color: White;
                }

                span {
                    display: inline-flex;
                    align-items: center;
                    gap: 6px;
                    line-height: 1;
                    font-size: 16px;
                }
            }

            .onhover-show-div {
                min-width: 120px;
                transition: all 0.3s linear;
                position: absolute;
                z-index: 3;
                border: 1px solid rgba($white, 0.10%);
                background-color: #1d2537;
                opacity: 0;
                visibility: hidden;
                transform: translate3d(0, -5%, 0);
                top: 45px;
                right: 0;
                padding: 15px;
                border-radius: 12px;
                width: max-content;


                [dir="rtl"] & {
                    left: 0;
                    right: unset;
                }

                .location {
                    cursor: pointer;
                    transition: all 0.3s;
                    @include flex_common_1 ($dis: flex, $align: center);
                    border: none;
                    color: rgba($white, 0.6);
                    background-color: transparent;
                    gap: 8px;
                    font-size: 15px;
                    padding: 0;

                    img {
                        border-radius: 0 !important;
                        width: auto !important;
                        height: auto !important;
                    }

                    i {
                        --Iconsax-Color: #ffffff99;
                    }

                    &:hover {
                        color: $white;
                        transform: translateX(5px);

                        [dir="rtl"] & {
                            transform: translateX(-5px);
                        }

                        i {
                            --Iconsax-Color: #fff;
                        }
                    }

                    +li {
                        margin-top: 16px;
                    }
                }
            }

            &:hover {
                .onhover-show-div {
                    opacity: 1;
                    visibility: visible;
                    transform: none;
                }
            }
        }

        .nav-right {
            @include flex_common_1 ($dis: flex, $align: center);
            gap: 12px;

            @include mq-max(md) {
                gap: 6px;
            }

            .nav-item-right {
                border: 1px solid rgba($white, 0.10%);
                background-color: rgba($white, 0.10%);

                padding: 7px 9px;
                border-radius: 100%;

                &.login-btn {
                    border: none;
                    background-color: transparent;
                    padding: 0;

                    a {
                        span {
                            @include mq-max(md) {
                                display: none;
                            }
                        }
                    }
                }

                .btn {
                    &.btn-outline {
                        border-radius: 30px;
                        padding: 8px 30px;
                        border-color: var(--theme-color);
                        color: var(--theme-color);
                        background-color: transparent;

                        @include mq-max(lg) {
                            padding: 4px 12px;
                            font-size: 16px;
                        }
                    }
                }

                &:last-child {
                    position: relative;
                    margin-left: 12px;

                    &:before {
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 20px;
                        background-color: rgba($white, 0.30%);
                        top: 50%;
                        left: -12px;
                        transform: translateY(-50%);
                    }

                }
            }

            .language-dropdown,
            .currency-dropdown,
            .profile-dropdown {
                @include flex_common_1 ($dis: flex, $align: center);
                gap: 6px;
                padding: 5px 8px;
                border-radius: 30px;

                img {
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                }

                .language-btn,
                .currency-btn,
                .profile-btn {
                    background: transparent;
                    color: white;
                    border: none;
                    line-height: 1;
                    padding: 0;
                    @include flex_common_1 ($dis: flex, $align: center);
                    gap: 12px;

                    span {
                        display: inline-flex;
                        align-items: center;
                        gap: 6px;
                        line-height: 1;
                        font-size: 16px;
                    }
                }

                .onhover-show-div {
                    min-width: 120px;
                    transition: all 0.3s linear;
                    position: absolute;
                    z-index: 3;
                    border: 1px solid rgba($white, 0.10%);
                    background-color: #1d2537;
                    opacity: 0;
                    visibility: hidden;
                    transform: translate3d(0, -5%, 0);
                    top: 45px;
                    right: 0;
                    padding: 15px;
                    border-radius: 12px;
                    width: max-content;

                    [dir="rtl"] & {
                        left: 0;
                        right: unset;
                    }

                    .lang,
                    .currency,
                    .profile,
                    .page-link {
                        cursor: pointer;
                        transition: all 0.3s;
                        @include flex_common_1 ($dis: flex, $align: center);
                        border: none;
                        color: rgba($white, 0.6);
                        background-color: transparent;
                        gap: 10px;
                        font-size: 15px;
                        padding: 0;
                        white-space: nowrap;

                        a {
                            color: rgba($white, 0.6);
                            display: flex;
                            align-items: center;
                            gap: 10px;

                        }

                        i {
                            --Iconsax-Color: #ffffff99;
                        }

                        img {
                            border-radius: 0 !important;
                            width: auto !important;
                            height: auto !important;
                        }

                        &:hover {
                            color: $white;
                            transform: translateX(5px);

                            a {
                                color: $white;
                            }

                            i {
                                --Iconsax-Color: #fff;
                            }

                            [dir="rtl"] & {
                                transform: translateX(-5px);
                            }
                        }

                        +li {
                            margin-top: 16px;
                        }
                    }
                }

                &:hover {
                    .onhover-show-div {
                        opacity: 1;
                        visibility: visible;
                        transform: none;
                    }
                }
            }

            .profile-dropdown {
                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                }

                .onhover-show-div {
                    top: 53px;
                }
            }

            i {
                --Iconsax-Size: 18px;
                --Iconsax-Color: White;
            }
        }

        .navbar-toggler {
            // margin-right: calc(10px + (26 - 10) * ((100vw - 320px) / (1920 - 320)));
            border: none;
            padding: 0;

            .navbar-toggler-icon {
                @include pseudowh($width: 25px, $height: 25px);
            }

            &:focus {
                box-shadow: none;
            }
        }

        .navbar-collapse {
            .navbar-nav {
                gap: calc(20px + (50 - 20) * ((100vw - 1200px) / (1920 - 1200)));

                .nav-item {
                    .nav-link {
                        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
                        color: rgba($white, 0.6);
                        padding-left: 0;
                        padding-right: 0;

                        &.active {
                            font-weight: 500;
                            color: $white;
                        }
                    }
                }
            }

            @include mq-max(xl) {
                position: fixed;
                top: 0;
                background-color: white;
                left: -320px;
                width: calc(300px + (320 - 300) * ((100vw - 1200px) / (1920 - 1200)));
                height: 100%;
                transition: all 0.3s ease-in-out;
                visibility: hidden;
                opacity: 0;

                .navbar-header {
                    padding: 20px;
                    @include flex_common ($dis: flex, $align: center, $justify: space-between);
                    box-shadow: 0 8px 10px rgba($title-color, 0.05);

                    h4 {
                        color: $primary-color;
                        font-weight: 600;
                        line-height: 23px;
                    }

                    i {
                        ---Iconsax-Size: 18px;
                        --Iconsax-Color: #808B97;
                    }
                }

                .navbar-nav {
                    padding: 20px;
                    height: 100%;
                    gap: 16px;
                    overflow: auto;

                    .nav-item {
                        .nav-link {
                            color: $title-color;
                            padding: 0;
                        }
                    }
                }

                &.show {
                    transition: all 0.3s ease-in-out;
                    left: 0;
                    z-index: 2;
                    visibility: visible;
                    opacity: 1;

                }

            }
        }
    }
}