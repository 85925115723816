/**=====================
    Animation scss
==========================**/

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes textShine {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

@keyframes textChange { 
    0% { 
        content: "User"; 
    } 
    30% { 
        content: "Provider"; 
    } 
    55% { 
        content: "Servicemen"; 
    } 
    80% { 
        content: "Admin"; 
    } 
} 

@keyframes tada{
    0% {
        transform: scaleX(1);
    }
    10% {
        transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    }
    20% {
        transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    }
    30% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    50% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    70% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    }
    40% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    60% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    }
    100% {
        transform: scaleX(1);
    }
}
