/**=====================
    Modal scss
==========================**/

.modal-backdrop{
    z-index: 5;
}

.modal {
    z-index: 6;
    .modal-dialog {
        .modal-content {
            border-radius: 12px;

            .modal-header {
                padding: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
                border: none;
                margin-bottom: 12px;
                margin: 0;

                .btn-close {
                    opacity: 0.9;
                    background-size: 12px;
                    &:focus{
                        border: none;
                        box-shadow: none;
                    }
                }
            }

            .modal-body {
                padding: 0 calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
                &.custom-scroll{
                    overflow: auto;
                    height: 630px;

                }
            
                .card{
                    margin: 0;
                }

                .modal-body-content {
                    background-color: $section-bg;
                    border-radius: 10px;
                    width: 100%;
                    height: calc(200px + (250 - 200) * ((100vw - 320px) / (1920 - 320)));
                    @include flex_common ($dis: flex, $align: center, $justify: center);
                    margin-bottom: 16px;
                    padding: 20px 0;

                    img {
                        height: 100%;
                    }
                }

                .cancel-content{
                    background-color: $section-bg;
                    border-radius: 10px;
                    width: 100%;
                    padding: 20px;
                    label{
                        font-size: 18px;
                        font-weight: 500;
                        list-style: 23px;
                        color: $title-color;
                        margin-bottom: 6px;
                    }
                }

                p {
                    font-size: 16px;
                    color: $title-color;
                }

                .btn {
                    &.btn-solid {
                        width: 100%;
                        justify-content: center;
                        border-radius: 12px;
                    }
                }
            }
            .modal-footer{
                border: none;
                padding: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
                flex-wrap: nowrap;
                gap: 12px;
                .btn{
                    margin: 0;
                    padding: calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320))) 16px;
                }
                .status-note{
                    width: 100%;
                    border-radius: 0;
                    margin: 0;
                }
                @include mq-max(sm){
                    // flex-wrap: wrap;
                }
            }
        }
    }

    &.reset-modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    text-align: center;
                }
            }
        }
    }

    &.book-now {
        .modal-dialog {
            .modal-content {
                .modal-body {

                    .service-item {
                        padding: 20px;
                        border-radius: 8px;
                        align-items: start;
                        margin-bottom: 20px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .serviceman {
                            @include flex_common_1 ($dis: flex, $align: center);
                            gap: 16px;

                            .serviceman-detail {
                                width: max-content;
                                @include flex_common_1 ($dis: flex, $align: center);
                                gap: 12px;
                                background-color: $section-bg;
                                border-radius: 12px;
                                padding: 12px 16px;

                                h6 {
                                    color: $light-color;
                                }

                                p {
                                    margin: 0;
                                    font-size: 14px;
                                    font-weight: 500;
                                }

                                .rate {
                                    .star {
                                        width: 15px !important;
                                        height: 15px !important;
                                    }
                                }
                            }
                        }

                        .btn-outline {
                            width: max-content;
                            border-radius: 8px;
                            margin-top: 4px;
                            font-size: 16px;
                            padding: 9px 18px;
                        }
                    }
                }
            }
        }
    }

    &.book-service {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .services {
                        label {
                            color: $title-color;
                            margin-bottom: 8px;
                            font-weight: 500;
                        }

                        .service {
                            @include flex_common_1 ($dis: flex, $align: center);
                            gap: 10px;
                            background-color: $section-bg;
                            padding: 20px;
                            border-radius: 10px;

                            img {
                                width: 80px;
                                height: 80px;
                            }

                            span {
                                font-size: 22px;
                                font-weight: 700;
                                color: $primary-color;
                            }
                        }



                    }
                }
            }
        }
    }

    &.location-modal {
        .modal-content{
            .modal-body{
                padding-bottom: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));;
            }
        }
        .location-map {
            border-radius: 12px;
            background-color: $section-bg;
            padding: 0;
            margin: 0;
            gap: 0;
            overflow: hidden;
            .map{
                height: 100%;
                overflow: hidden;
                iframe{
                    height: 100%;
                }
            }

            label {
                color: $title-color;
                font-size: 18px;
            }

            .location-detail {
                padding: 20px;
            }

            .category {
                @include flex_common_1 ($dis: flex, $align: center);
                flex-wrap: wrap;
                gap: calc(11px + (40 - 11) * ((100vw - 320px) / (1920 - 320)));

                .form-check {
                    @include mq-max(sm){
                        gap: 8px;
                    }
                    label {
                        color: $light-color;
                        font-size: 16px;
                    }

                    .form-radio-input {
                        &:checked {
                            ~label {
                                color: $title-color;
                            }
                        }
                    }
                }
            }
            i {
                --Iconsax-Size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                --Iconsax-Color: #808B97;
            }
            .input-icon{
                position: absolute;
                top: calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320)));
                left: 16px;
            }
        }
    }

    &.servicemen-list-modal{
        
        .search{
            margin-bottom: 16px;
            input{
                border-radius: 30px !important;
            }
        }
        .select-dropdown{
            border: none;
            margin: 0;
            padding-bottom: 16px;
            @include mq-max(lg){
                flex-direction: column;
                align-items: flex-start;
                gap: 12px;
                .form-group{
                    width: 100%;
                }
            }
        }
    }

    &.servicemen-detail-modal{
        .modal-dialog {
            .modal-content {
                
                .modal-header{
                    position: relative;
                    .modal-title{
                        padding-left: 24px;
                    }
                    i{
                        --Iconsax-Size: 18px;
                        --Iconsax-Color: #00162E;                        
                    }
                    button{
                        padding: 0;
                        border: none;
                        background: unset;
                        position: absolute;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        left: 20px;
                    }
                }
                .modal-body{
                    .provider-content{
                        padding: 0;
                        border: none;
                        p{
                            text-align: start;
                        }
                    }
                    i{
                        --Iconsax-Size: 18px;
                        --Iconsax-Color: #808B97;
                    }
                    .profile-bg{
                        position: relative;
                        background-image: url(../../assets/images/profile-bg.png);
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        z-index: 0;
                        height: 100px;
                    }
                    .profile{
                        z-index: 1;
                        position: relative;
                        margin-top: -60px;
                        .img{
                            border: 4px solid $white;
                            width: 100px;
                            height: 100px;
                        }
                        .rate{
                            position: relative;
                            margin: 0;
                            padding-left: 8px;
                            &:before{
                                position: absolute;
                                content: "";
                                top: 7px;
                                left: 0;
                                background-color: #E5E8EA;
                                width: 1px;
                                height: 12px;
                            }
                            .star{
                                width: 13px;
                                height: 13px;
                            }
                        }
                        
                    }
                    .profile-info{
                        background-color: $section-bg;
                        border-radius: 10px;
                        column-count: 2;
                        padding: 16px 20px;
                        label{
                            @include flex_common_1 ($dis: flex, $align: center);
                            gap: 6px;
                            line-height: 1;
                        }
                    }
                    .information{
                        p{
                            font-size: 16px;
                            font-weight: 500;
                        }
                        .btn{
                            font-size: 16px;
                            width: max-content;
                            border-radius: 6px;
                            padding: 6px 12px;
                        }
                    }
                    .note{
                        font-weight: 400;
                        color: $light-color;
                        margin-top: 8px;
                    }
                    .expert{
                        @include flex_common_1 ($dis: flex, $align: center);
                        gap: 30px;
                        margin-top: 0.5rem;
                        list-style: disc;
                        padding-left: 30px;
                        li{
                            display: list-item;
                        }
                    }
                }
            }
        }
    }

    &.service-charge-modal{
        .modal-dialog {
            .modal-content {
                .modal-body{
                    padding-bottom: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320))) !important;
                    .bill-summary{
                        .charge, .total{
                            background-color: $section-bg;
                        }
                        .total{
                            &:before, &:after{
                                background-color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    &.coupon-modal{
        .modal-content{
            .modal-body{
                padding-bottom: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320))) !important;
            }
        }
    }

    &.confirm-modal, &.fail-modal{
        .modal-body {
            p {
                color: $light-color !important;
                line-height: 25px;
                text-align: center;
                margin: 0 calc(5px + (25 - 5) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }

    &.wallet-modal{
        .modal-dialog{
            .modal-content{
                .modal-body{
                    .add-money{
                        background-color: $section-bg;
                        padding: 20px;
                        border-radius: 10px;
                        label{
                            color: $title-color;
                        }
                        .input-group{
                            input{
                                border-radius: 8px;
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &.pending-modal, 
    &.accepted-modal, 
    &.ongoing-modal, 
    &.completed-modal{
        .modal-body{
            &.booking-sec{
                .card{
                    border: none;
                }
                .primary-badge{
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    @include mq-max(lg){
                        top: 10px;
                        left: 10px;
                    }
                }
                .status{
                    margin-bottom: 4px;
                    @include mq-max(lg){
                        flex-direction: column;
                        align-items: start;
                        justify-content: start;
                    }
                    h5{
                        font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
                        color: $title-color;
                        &:hover{
                            text-decoration: unset;
                        }
                    }
                    .status-btn{
                        font-size: 20px;
                        &:hover{
                            text-decoration: unset;
                        }
                    }
                }
                .view-status{
                    align-items: start;
                    margin-bottom: 10px;
                    button{
                        @include flex_common_1 ($dis: flex, $align: center);
                        gap: 4px;
                        border-radius: 4px;
                        border: none;
                        i{
                            --Iconsax-Size: 18px;
                            --Iconsax-Color: #575756;
                        }
                    }
                }

                .status-note{
                    color: $danger-color;
                    padding: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
                    border-radius: 8px;
                    background-color: rgba($danger-color, 0.10);
                    font-size: 14px;
                    text-align: center;
                    span{
                        font-weight: 500;
                    }
                }
                .bill-summary{
                    label{
                        color: $title-color;
                        font-weight: 500;
                        margin-bottom: 8px;
                    }
                    .charge, .total{
                        background-color: $section-bg;
                        border-color: $gray-color;
                    }
                    .charge{
                        border-bottom: none;
                        p{
                            color: $light-color;
                            flex-wrap: wrap;
                        }
                    }
                    .total{
                        &:before, &:after{
                            background-color: $white;
                        }
                    }
                    .total-amount{
                        background-color: $section-bg;
                        padding: 20px;
                        border: 1px solid $gray-color;
                        border-bottom: none;
                        li{
                            @include flex_common ($dis: flex, $align: center, $justify: space-between);
                            gap: 4px;
                            font-size: 16px;
                            &.total-amount-data{
                                font-weight: 700;
                            }
                        }
                    }
                    .circle{
                        height: 16px;
                        margin: 0;
                        margin-top: -8px;
                        margin-bottom: -8px;
                    }
                }

                .payment-summary{
                    label{
                        color: $title-color;
                        font-weight: 500;
                        margin-bottom: 8px;
                    }
                    .charge{
                        background-color: $section-bg;
                        border-color: $gray-color;
                        border-radius: 8px;
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        position: relative;
                        &:after, &:before{
                            content: "";
                            position: absolute;
                            pointer-events: none;
                            border: solid transparent;
                            display: block;
                            border-width: 1px;            
                            width: 10px;
                            height: 15px;
                            bottom: 48px;
                            border-radius: 100%;
                            background-color: $white;
                        }
                
                        &:after{
                            border-left-color: $gray-color;
                            border-top-color: $gray-color;
                            border-bottom-color: $gray-color;
                            right: -2px;
                        }
                        &:before{
                            border-right-color: $gray-color;
                            border-top-color: $gray-color;
                            border-bottom-color: $gray-color;
                            left: -2px;
                        }
                    }
                    .circle{
                        height: 18px;
                        margin: -11px 0;
                    }
                }

                .extra-service{
                    label{
                        color: $title-color;
                        font-weight: 500;
                        margin-bottom: 8px;
                    }
                    .total-amount{
                        @include flex_common ($dis: flex, $align: center, $justify: space-between);
                        gap: 4px;
                        border: 1px solid $gray-color;
                        border-radius: 12px;
                        padding: 16px;
                        h4{
                            font-weight: 500;
                            line-height: 23px;
                            margin-bottom: 6px;
                        }
                        p{
                            margin: 0;
                            color: $primary-color;
                            line-height: 18px;
                        }
                        .receipt{
                            background-color: rgba($primary-color, 0.10);
                            border-radius: 4px;
                            height: 50px;
                            width: 50px;
                            @include flex_common ($dis: flex, $align: center, $justify: center);
                            .receipt-img{
                                width: 25px;
                                height: 25px;
                            }
                        }
                    }
                }
                .profile-view{
                    background-color: rgba($primary-color, 0.10);
                    border-radius: 5px;
                    @include flex_common ($dis: flex, $align: center, $justify: center);
                    width: 30px;
                    height: 30px;
                    border: none;
                    i{
                        --Iconsax-Size: 18px;
                        --Iconsax-Color: #575756;
                    }
                }
                .chat{
                    background-color: $section-bg;
                    border-radius: 5px;
                    @include flex_common ($dis: flex, $align: center, $justify: center);
                    width: 30px;
                    height: 30px;
                    border: none;
                    i{
                        --Iconsax-Size: 18px;
                        --Iconsax-Color: #808B97;
                    }
                }
            }
        }
    }

    &.status-modal{
        .modal-content{
            .modal-header{
                .modal-back{
                    position: absolute;
                    top: calc(16px + (29 - 16) * ((100vw - 320px) / (1920 - 320)));
                    left: 24px;
                    background-color: unset;
                    padding: 0;
                    border: none;
                }
                i{
                    --Iconsax-Size: 18px;
                    --Iconsax-Color: #00162E;
                }
                .modal-title{
                    padding-left: 25px;
                }
            }
            .modal-body{
                padding: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
                .pattern-btn-1{
                    padding: 16px 20px;
                    &:before, &:after{
                        background-color: $white;
                    }
                }
                .form-control.pattern-input{
                    position: relative;
                    padding: 16px 20px;
                    vertical-align: middle;
                    &:after, &:before{
                        content: "";
                        position: absolute;
                        pointer-events: none;
                        border: dashed transparent;
                        display: block;
                        border-width: 1px;            
                        width: 12px;
                        height: 12px;            
                        border-radius: 100%;
                        background-color: $white;
                        z-index: 1;
                    }
                
                    &:after{
                        border-top-color: $primary-color;;
                        border-left-color: $primary-color;;
                        border-bottom-color: $primary-color;;
                        right: -100px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &:before{
                        border-top-color: $primary-color;;
                        border-right-color: $primary-color;;
                        border-bottom-color: $primary-color;;
                        left: -3px;
                        top: 50%;
                        transform: translateY(-50%);
                    
                    }
                }

                .status-history{
                    margin-top: 24px;
                    ul{
                        padding-left: 10px;
                        display: flex;
                        flex-direction: column;
                        gap: 30px;
                        li{
                            @include flex_common_1 ($dis: flex, $align: center);
                            gap: 16px;
                            @include mq-max(xs){
                                flex-wrap: wrap;
                                gap: 8px;
                            }
                            i{
                                --Iconsax-Size: 24px;
                                --Iconsax-Color: #E5E8EA;
                            }
                            p{
                                margin: 0;
                            }
                            .status-time{
                                line-height: 20px;
                                color: $light-color;
                                white-space: nowrap;
                            }
                            .status-title{
                                font-size: 15px;
                                line-height: 20px;
                            }
                            .status-des{
                                font-size: 15px;
                                line-height: 20px;
                                color: $light-color;
                            }
                            .status-main{
                                padding-left: 16px;
                                position: relative;
                                width: 100%;
                                @include mq-max(xs){
                                    padding-left: 42px;
                                }
                                &:before{
                                    content: "";
                                    position: absolute;
                                    top: 50%;
                                    left: 0;
                                    transform: translateY(-50%);
                                    background-color: $gray-color;
                                    height: 26px;
                                    width: 1px;
                                    @include mq-max(xs){
                                        display: none;
                                    }
                                }
                                .dashed-border{
                                    position: absolute;
                                    content: "";
                                    height: 1px;
                                    width: 100%;
                                    left: 0;
                                }
                            }

                            &.recent{
                                .activity-dot{
                                    background-color: $primary-color;
                                    outline-color: $primary-color;
                                    &:after{
                                        border: 1px dashed $primary-color;
                                    }
                                }
                                i{
                                    --Iconsax-Size: 24px;
                                    --Iconsax-Color: #575756;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.start-service-modal, &.restart-service-modal{
        .modal-body{
            text-align: center;
            .modal-body-content{
                flex-direction: column;
                .ellipse{
                    width: 220px;
                    padding-bottom: 20px;
                }
            }
        }
    }

    &.pause-service-modal{
        .modal-body{
            text-align: center;
            .modal-body-content{
                position: relative;
                flex-direction: column;
                .ellipse{
                    width: 100px;
                    height: auto !important;
                }
                .hold{
                    position: absolute;
                    top: 40px;
                    right: 70px;
                    height: auto !important;
                    
                    @include mq-max(sm){
                        height: 40px !important;
                    }
                    @include mq-max(xs){
                        right: 10px;
                    }
                }
            }
        }
    }

    &.completed-service-modal{
        .modal-content{
            .modal-body{
                text-align: center;
                .modal-body-content{
                    position: relative;
                    .success-tick{

                    }
                    .girl-on-chair{

                    }
                    // img{
                    //     height: auto !important;
                    // }
                    .success-tick{
                        top: 50px;
                        left: 35%;
                        -webkit-transform: translateX(-30%);
                        transform: translateX(-35%);
                        position: absolute;
                        height: 40px;
                    }
                    .girl-on-chair{
                        position: absolute;
                        top: 20px;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 210px;
                    }
                }
            }
        }
    }
 
    &.review-modal{
        .modal-content{
            .modal-body{
                .rate-content{
                    background-color: $section-bg;
                    padding: 20px;
                    border-radius: 12px;
                    p{
                        text-align: center;
                        margin: 0;
                        color: $light-color;
                    } 
                    .form-group{
                        label{
                            font-size: 18px;
                            font-weight: 500;
                            color: $title-color;
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }

    &.profile-update-modal{
        .update-img{
            display: flex;
            margin: 0 auto;
            width: max-content;
            position: relative;
            margin-bottom: -50px;
            img{
                width: 120px;
                height: 120px;
                border-radius: 100%;
                border: 2px solid $white;
            }
            .update-profile{
                --Iconsax-Size: 20px;
                --Iconsax-Color: #575756;
                background-color: $section-bg;
                border: 2px solid $white;
                height: 40px;
                width: 40px;
                border-radius: 100%;
                position: absolute;
                bottom: 0px;
                right: 0px;
                cursor: pointer;
                @include flex_common ($dis: flex, $align: center, $justify: center);
            }
        }
        .update-detail{
            background-color: $section-bg;
            border-radius: 10px;
            padding: 20px;
            padding-top: 70px;
        }

        input[type="file"] {
            display: none;
        }

    }
}
.update-detail{

    label{
        font-size: 18px;
        font-weight: 500;
        color: $title-color;
        margin-bottom: 4px;
    }
    .form-group{
        position: relative;
        .form-control{
            border: none;
            padding-left: 46px;
        }
        i {
            --Iconsax-Size: 20px;
            --Iconsax-Color: #808B97;
            position: absolute;
            top: 43px;
            left: 15px;
            
        }
        .toggle-password{
            position: absolute;
            top: 45px;
            right: 15px;
            left: unset;
            cursor: pointer;
            i{
                position: static;
            }
            .eye-slash{
                display: none;
            }
            &.eye{
                i.eye{
                    display: block;
                }
                i.eye-slash{
                    display: none;
                }
            }
            &.eye-slash{
                i.eye-slash{
                    display: block;
                }
                i.eye{
                    display: none;
                }
            }
        }
    }
}

.select-option {
    border: 1px solid $gray-color;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: $light-color;

    .form-check {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        border-bottom: 1px solid $gray-color;
        padding-bottom: 12px;

        &:last-child {
            border: none;
            padding-bottom: 0;
        }

        Label {
            font-size: 16px;
            color: $light-color !important;
        }
    }

    .form-radio-input {
        &:checked {
            ~label {
                font-weight: 500;
                color: $title-color !important;
            }
        }
    }
}

.select-servicemen {
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    border: 1px solid $gray-color;
    padding: 16px;
    border-radius: 10px;
    background-color: $white;
    gap: 12px;
    p {
        margin: 0;
        font-size: 16px;
    }
    @include mq-max(sm){
        flex-direction: column;
        align-items: start;
    }
}

.servicemen-list{
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: auto;
    height: 450px;
}
.servicemen-list-item{
    border: 1px solid $gray-color;
    padding: 10px 12px;
    border-radius: 10px;
    gap: 12px;
    box-shadow: 0px 4px 4px 0px rgba($title-color, 0.06);
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    .list{
        @include flex_common_1 ($dis: flex, $align: center);
        gap: 12px;
        @include mq-max(sm){
            gap: 6px;
        }
        p{
            margin: 0;
            color: $light-color !important;
            font-size: 14px !important;
        }  
        ul{
            @include flex_common_1 ($dis: flex, $align: center);
            gap: 12px;
        }
        h5{
            font-weight: 500;
            @include mq-max(2xs){
                font-size: 14px;
                width: 70px;
                overflow: hidden;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: nowrap;
                text-align: left;
                text-overflow: ellipsis;
            }
        }
        .rate{
            margin: 0;
            position: relative;
            padding-left: 12px;
            .star{
                width: 13px;
                height: 13px;
            }
            &:before{
                position: absolute;
                content: "";
                top: 2px;
                left: 0;
                background-color: $gray-color;
                width: 1px;
                height: 12px;
            }
        }  
        .detail{
            font-size: 16px;
            font-weight: 500;
            border: none;
            background: none;
            padding: 0;
            line-height: 1;
            @include mq-max(2xs){
                font-size: 14px;
                width: 100px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;
            }
            &:hover{
                color: $primary-color;
            }
        }    
        .img-45{
            @include mq-max(sm){
                width: 35px !important;
                height: 35px !important;
            }
        }
    }
}

.status-note{
    color: $danger-color;
    padding: 16px;
    border-radius: 8px;
    background-color: rgba($danger-color, 0.10);
    font-size: 14px;
    text-align: center;
    span{
        font-weight: 500;
    }
}

.emoji-tab{
    @include flex_common ($dis: flex, $align: center, $justify: center);
    gap: 10px;
   .emoji-icon{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
        h4{
            margin-top: 8px;
            color: $light-color;
            @include mq-max(xs){
                display: none;             
            }                
        }
     .emojis{
        padding: 11px;
        background-color: $white;
        border-radius: 12px;
        border: 1px solid $gray-color;
        @include flex_common ($dis: flex, $align: center, $justify: center);
        cursor: pointer;
        @include mq-max(xs){
            padding: 9px;
        }
        @include mq-max(2xs){
            padding: 7px;
        }
        .emoji{
            width: 50px;
            height: 50px;
            @include mq-max(xs){
                width: 30px;
                height: 30px;                
            }
            @include mq-max(2xs){
                width: 25px;
                height: 25px;
            }                      
        }
        .deactive{
            display: block;
        }
        .active{
            display: none;
        }
    }
    &.active{
        .emojis{
            border: 2px solid $primary-color;
        }
        .deactive{
            display: none;
        }
        .active{
            display: block;
        }
    }
   }
}