/**=====================
     Typography scss
==========================**/

body {
     font-family: $dm-sons;
     position: relative;
     font-size: 14px;
     margin: 0;
     background-color: $white;
     color: $title-color;
     // overflow-x: hidden;
}

ul {
     padding-left: 0;
     margin-bottom: 0;
}

li {
     display: inline-block;
     font-size: 14px;
}

p {
     font-size: 14px;
     line-height: 18px;
     margin: 0;
}

a {
     color: var(--theme-color);
     transition: 0.5s ease;
     text-decoration: none;

     &:hover {
          color: var(--theme-color);
          text-decoration: none;
          transition: 0.5s ease;
     }

     &:focus {
          outline: none;
     }
}

button {
     &:focus {
          outline: none;
     }
}

:focus {
     outline: none;
}

label{
     font-size: 16px; 
     color: $light-color;
}

h1 {
     font-size: calc(22px + (60 - 22) * ((100vw - 320px) / (1920 - 320)));
     font-weight: 500;
     line-height: 1.1;
     text-transform: capitalize;
     margin: 0;
}

h2 {
     font-size: calc(20px + (26 - 20) * ((100vw - 320px) / (1920 - 320)));
     font-weight: 600;
     text-transform: capitalize;
     margin: 0;
}

h3 {
     font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
     font-weight: 500;
     line-height: 1.2;
     margin: 0;
}

h4 {
     font-size: calc(17px + (18 - 17) * ((100vw - 320px) / (1920 - 320)));
     line-height: 1.2;
     margin: 0;
     font-weight: 400;
}

h5 {
     font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
     line-height: 1.2;
     margin: 0;
     font-weight: 400;
}

h6 {
     font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
     line-height: 1.2;
     margin: 0;
     font-weight: 400;
}

span {
     display: inline-block;
}

.theme-color {
     color: var(--theme-color) !important;
}

.theme-bg-color {
     background: var(--theme-color) !important;
}

.col-custom-3{
     width: 29%;
     @include mq-max(2xl){
          width: 36%;
     }
     @include mq-max(xl){
          width: 300px;
          position: relative;
     }
}

.col-custom-9{
     width: 71%;
     @include mq-max(2xl){
          width: 64%;
     }
     @include mq-max(xl){
          width: 100%;
     }
}

.w-max{
     width: max-content !important;
}

.w-auto{
     width: auto !important;
}